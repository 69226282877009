@charset "UTF-8";
@font-face {
  font-family: "RobotoLight";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoLight/RobotoLight.eot");
  src: url("/fonts/RobotoLight/RobotoLight.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoLight/RobotoLight.woff") format("woff"), url("/fonts/RobotoLight/RobotoLight.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoBold/RobotoBold.eot");
  src: url("/fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoBold/RobotoBold.woff") format("woff"), url("/fonts/RobotoBold/RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBlack";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoBlack/RobotoBlack.eot");
  src: url("/fonts/RobotoBlack/RobotoBlack.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoBlack/RobotoBlack.woff") format("woff"), url("/fonts/RobotoBlack/RobotoBlack.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoRegular/Roboto.eot");
  src: url("/fonts/RobotoRegular/Roboto.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoRegular/Roboto.woff") format("woff"), url("/fonts/RobotoRegular/Roboto.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoThin";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoThin/RobotoThin.eot");
  src: url("/fonts/RobotoThin/RobotoThin.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoThin/RobotoThin.woff") format("woff"), url("/fonts/RobotoThin/RobotoThin.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoLight/RobotoLight.eot");
  src: url("/fonts/RobotoLight/RobotoLight.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoLight/RobotoLight.woff") format("woff"), url("/fonts/RobotoLight/RobotoLight.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoBold/RobotoBold.eot");
  src: url("/fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoBold/RobotoBold.woff") format("woff"), url("/fonts/RobotoBold/RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBlack";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoBlack/RobotoBlack.eot");
  src: url("/fonts/RobotoBlack/RobotoBlack.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoBlack/RobotoBlack.woff") format("woff"), url("/fonts/RobotoBlack/RobotoBlack.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoRegular/Roboto.eot");
  src: url("/fonts/RobotoRegular/Roboto.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoRegular/Roboto.woff") format("woff"), url("/fonts/RobotoRegular/Roboto.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoThin";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoThin/RobotoThin.eot");
  src: url("/fonts/RobotoThin/RobotoThin.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoThin/RobotoThin.woff") format("woff"), url("/fonts/RobotoThin/RobotoThin.ttf") format("truetype");
}

body, html {
  font-family: Arial, sans-serif;
  line-height: 1.65;
  font-size: 16px !important;
}

body {
  counter-reset: custom-counter-1 custom-counter-2;
}

::input-placeholder {
  color: #999;
}

::placeholder {
  color: #999;
}

.wrap {
  line-height: 1.65;
  padding: 40px 15px;
}

.desc {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0 20px;
}

.h1 {
  color: #1422aa;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  padding-bottom: 20px;
}

.h2 {
  font-family: Roboto, sans-serif;
  font-size: 40px;
  padding: 30px 0 15px;
  text-align: center;
}

.h2-blue {
  font-size: 32px;
  font-family: Arial, sans-serif !important;
  text-align: center;
  padding: 10px 0;
  color: #1422aa;
  font-weight: 700;
}

.h4 {
  font-family: Arial, sans-serif !important;
  font-size: 1.1em;
  color: #999;
  font-weight: 700;
}

.h4-blue {
  color: #1422aa;
  font-family: Arial, sans-serif !important;
  font-size: 1.1em;
  font-weight: 500;
  text-transform: uppercase;
}

.h5-blue {
  text-transform: uppercase;
  font-family: Arial, sans-serif !important;
  font-weight: 700;
  color: #1422aa;
  font-size: 1em;
}

.h5-gray {
  text-transform: uppercase;
  font-family: Arial, sans-serif !important;
  font-weight: 700;
  color: #999;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Roboto, sans-serif;
}

.z-index {
  z-index: 20 !important;
}

.menu-wrap .buttom-menu li.active {
  background-color: #a3d3e5;
}

.menu-wrap .buttom-menu li.active a {
  color: #000000 !important;
}

.form-control {
  padding: 0 20px;
  color: #333;
  height: 50px;
  background: #f0f0f0;
  font-size: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control.input-sm {
  height: 30px;
  font-size: 14px;
}

.input-group-addon {
  border: none;
  border-radius: 0;
  background-color: #cccccc;
}

.input-group-addon:first-child {
  border-right: 1px solid #cccccc;
}

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.modal-content {
  border-radius: 0;
}

.counter-1::before {
  counter-increment: custom-counter-1;
  content: counter(custom-counter-1) ". ";
}

.counter-2::before {
  counter-increment: custom-counter-2;
  content: counter(custom-counter-2) ". ";
}

.counter-list {
  counter-reset: custom-counter-3;
}

.counter-list .counter-item::before {
  counter-increment: custom-counter-3;
  content: counter(custom-counter-3) ". ";
}

.listener-item-programs {
  counter-reset: custom-counter-4;
}

.listener-item-programs .counter-noi::before {
  content: counter(custom-counter-4);
}

.listener-item-programs .counter::before {
  counter-increment: custom-counter-4;
  content: counter(custom-counter-4);
}

.counter-listeners {
  counter-reset: custom-counter-listeners;
}

.counter-listeners li {
  list-style: none;
  margin-bottom: 3em;
  margin-top: 2em;
}

.counter-listeners li::before {
  display: inline-block;
  margin-bottom: 1em;
  counter-increment: custom-counter-listeners;
  content: "Слушатель №" counter(custom-counter-listeners) " ";
  font-weight: bold;
}

.form-check label {
  cursor: pointer;
  position: relative;
  padding-left: 1.7em;
}

.form-check input[type="checkbox"], .form-check input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: -15px;
}

.form-check input[type="checkbox"] + .label-text {
  font-weight: normal;
}

.form-check input[type="checkbox"] + .label-text:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 120%;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 0.4em;
  margin-left: -1.4em;
  color: #999999;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.form-check input[type="checkbox"]:checked + .label-text:before {
  content: "\f058";
  color: #1422aa;
  animation: effect 250ms ease-in;
}

.form-check input[type="checkbox"]:disabled + .label-text {
  color: #aaa;
}

.form-check input[type="checkbox"]:disabled + .label-text:before {
  content: "\f058";
  color: #ccc;
}

.form-check input[type="radio"] + .label-text {
  font-weight: normal;
}

.form-check input[type="radio"] + .label-text::before {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  border-radius: 50%;
  color: #999;
  content: '\f111';
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-size: 120%;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  margin-left: -1.4em;
  margin-right: .4em;
  speak: none;
  text-transform: none;
  width: 1em;
}

.form-check input[type="radio"]:checked + .label-text::before {
  animation: effect 250ms ease-in;
  color: #1422aa;
  content: '\f192';
}

.form-check input[type="radio"]:disabled + .label-text {
  color: #aaa;
}

.form-check input[type="radio"]:disabled + .label-text::before {
  color: #ccc;
}

.form-check .toggle input[type='radio'] + .label-text::before {
  -webkit-font-smoothing: antialiased;
  color: #999;
  content: '\f204';
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  margin-right: 10px;
  speak: none;
  text-transform: none;
  width: 1em;
}

.form-check .toggle input[type='radio']:checked + .label-text:before {
  content: "\f205";
  color: #16a085;
  animation: effect 250ms ease-in;
}

.form-check .toggle input[type='radio']:disabled + .label-text {
  color: #aaa;
}

.form-check .toggle input[type='radio']:disabled:before {
  content: "\f204";
  color: #ccc;
}

.form-check .yan_custom input[type="checkbox"]:disabled + .label-text {
  color: #ff033e;
}

.form-check .yan_custom input[type="checkbox"] + .label-text::before {
  color: #ff033e;
}

.form-check .yan_custom input[type="checkbox"]:checked + .label-text:before {
  color: #ff033e;
}

@keyframes effect {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.form-horizontal .control-label {
  text-align: left !important;
  padding-top: 10px;
  font-weight: 500;
}

.checkbox-custom {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 50px;
}

.checkbox-custom span {
  margin: 0 10px;
}

.checkbox-custom input[type="checkbox"] {
  margin: 0;
  position: relative;
  width: 80px;
  height: 20px;
  appearance: none !important;
  background: #dddddd;
  border-radius: 20px;
  transition: all 0.5s ease;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none !important;
  cursor: pointer;
}

.checkbox-custom input[type="checkbox"]::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  transform: scale(1.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
  z-index: 1;
}

.checkbox-custom input:checked[type="checkbox"] {
  background: #1422aa;
  outline: none !important;
}

.checkbox-custom input:checked[type="checkbox"]::before {
  left: 60px;
}

.razvitum-pagination {
  border: 0;
  border-radius: 0;
}

.razvitum-pagination > li > a,
.razvitum-pagination > li > span {
  border: 0;
  border-radius: 0;
  color: #1422aa;
}

.razvitum-pagination > .active > a {
  background-color: #1422aa;
  border-color: #1422aa;
}

.razvitum-pagination > .active > a:focus, .razvitum-pagination > .active > a:hover {
  background-color: #1422aa;
  border-color: #1422aa;
}

.razvitum-pagination > .active > a span {
  background-color: #1422aa;
  border-color: #1422aa;
}

.razvitum-pagination > .active > a span:focus, .razvitum-pagination > .active > a span:hover {
  background-color: #1422aa;
  border-color: #1422aa;
}

.affix {
  position: sticky;
}

.s-saying {
  background-color: #f2f2f2;
  padding: 50px 0;
}

.s-beruza {
  background-color: #a3d3e5;
  padding: 50px 0;
}

.blue {
  color: #1422aa !important;
}

.beruza {
  color: #a3d3e5 !important;
}

.gray {
  color: #999 !important;
}

.dark-gray {
  color: #737373 !important;
}

.red {
  color: #e4201b !important;
}

.green {
  color: #3fa435 !important;
}

.orange {
  color: #fecf73 !important;
}

.white {
  color: #ffffff !important;
}

.bg-grey {
  background-color: #f2f2f2;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip.right .tooltip-inner {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.tooltip.right .tooltip-arrow {
  border-right-color: #FFF !important;
}

.tooltip.right .tooltip-arrow:after {
  content: '';
  position: relative;
  border-width: 5px 5px 5px 0;
  border-right-color: #1422aa !important;
  left: 2px;
  top: 50%;
  margin-top: -5px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  color: transparent;
}

.tooltip.top .tooltip-inner {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.tooltip.top .tooltip-arrow {
  border-top-color: #FFF !important;
}

.tooltip.top .tooltip-arrow:after {
  content: '';
  position: relative;
  border-width: 5px 5px 0;
  border-top-color: #1422aa !important;
  left: 50%;
  bottom: 7px;
  margin-left: -5px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  color: transparent;
}

.tooltip-inner {
  padding: 10px;
  background: #1422aa;
  color: #ffffff;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #fff !important;
}

.file-wrap {
  width: 100%;
  position: relative;
  height: 56px;
  border-radius: 10em;
  border: 1px solid #bcbcbc;
  overflow: hidden;
}

.file-wrap input[type="file"] {
  display: none;
  opacity: 0;
}

.file-wrap label {
  position: relative;
  width: 100%;
  height: 100%;
}

.file-wrap_icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  font-size: 24px;
  color: #1422aa;
}

.file-wrap_text {
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  font-weight: 500;
  color: #545454;
}

.file-wrap_btn {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}

.razvitum-steps {
  padding: 30px 0;
  font-size: 0.8em;
}

.razvitum-steps ol {
  list-style: none;
  display: table;
  margin: 0;
  padding: 0;
  counter-reset: list 0;
  width: 100%;
}

.razvitum-steps ol li {
  position: relative;
  display: table-cell;
  width: 1%;
}

.razvitum-steps ol li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 95%;
  height: 1px;
  display: block;
  background-color: #000;
}

.razvitum-steps ol li:last-child::before {
  display: none;
}

.razvitum-steps ol li a {
  color: #000000;
}

.razvitum-steps ol li.active {
  font-weight: bold;
}

.razvitum-steps ol li.active i.counter::before {
  color: #FFFFFF;
}

.razvitum-steps ol li.active i.counter::after {
  background-color: #fecf73;
}

.razvitum-steps ol li.done i.counter::before {
  color: #FFFFFF;
}

.razvitum-steps ol li.done i.counter::after {
  background-color: #1422aa;
}

.razvitum-steps ol li span {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #ffffff;
  padding-right: 10px;
  vertical-align: middle;
}

.razvitum-steps ol li span i.counter {
  border-radius: 50%;
  overflow: hidden;
  min-width: 46px;
  width: 46px;
  height: 46px;
  display: block;
  position: relative;
  font-style: normal;
  margin-right: 10px;
}

.razvitum-steps ol li span i.counter::before {
  counter-increment: list;
  content: counter(list);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 14px;
}

.razvitum-steps ol li span i.counter::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 105%;
  height: 105%;
  display: block;
  z-index: 1;
  background-color: #f2f2f2;
}

.badge.badge-danger {
  background-color: #e4201b !important;
  color: #ffffff !important;
}

.collapsed .caret {
  transform: rotate(-90deg);
}

.input-range-wrap input[type="range"] {
  appearance: none;
  width: 100%;
  height: 20px;
  padding: 0;
  border-radius: 50px;
  border: 1px solid #ddd;
  box-shadow: inset 0 0 5px rgba(153, 153, 153, 0.3);
  outline: none;
}

.input-range-wrap input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(153, 153, 153, 0.3);
  cursor: pointer;
}

.tutor-online {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 25px;
  margin-left: 5px;
  position: absolute;
  right: 0;
  bottom: 10px;
  font-size: 13px;
}

.tutor-online-text {
  color: #1422aa;
  margin-right: 15px;
}

.tutor-online-icon {
  display: flex;
  align-items: center;
  margin-right: 5px;
  transition: all 0.3s linear;
}

.tutor-online-icon a {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.tutor-online-icon a span.text {
  opacity: 1;
  display: block;
}

.tutor-online-icon a span.icon {
  background-color: #a3d3e5;
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
}

.tutor-online-icon a span.icon i {
  color: #1422aa;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.tutor-online-icon.text-1 span.text {
  animation-name: tutor-animation;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-timing-function: ease;
  font-size: 0em;
}

.tutor-online-icon.text-2 span.text {
  animation-name: tutor-animation;
  animation-duration: 2s;
  animation-delay: 5s;
  animation-timing-function: ease;
  font-size: 0em;
}

.tutor-online-icon.text-3 span.text {
  animation-name: tutor-animation;
  animation-duration: 2s;
  animation-delay: 8s;
  animation-timing-function: ease;
  font-size: 0em;
}

@keyframes tutor-animation {
  0% {
    font-size: 0em;
    opacity: 0;
  }
  25% {
    font-size: 1em;
    opacity: 1;
  }
  50% {
    font-size: 1em;
    opacity: 1;
  }
  75% {
    font-size: 1em;
    opacity: 1;
  }
  100% {
    font-size: 0em;
    opacity: 0;
  }
}

.lms-menu-wrap {
  position: relative;
}

.lms-menu-wrap .lms-menu {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;
  transition: width 0.1s linear;
}

.lms-menu-wrap .lms-menu #btn-menu-bars {
  cursor: pointer;
}

.lms-menu-wrap .lms-menu i {
  font-style: normal;
}

.lms-menu-wrap .lms-menu ul.fa-ul {
  margin: 0;
}

.lms-menu-wrap .lms-menu ul.fa-ul li {
  position: relative;
  white-space: nowrap;
  padding: 1.2em 0;
  cursor: pointer;
  color: #777777;
  margin-left: 10px;
}

.lms-menu-wrap .lms-menu ul.fa-ul li a {
  color: #777777;
}

.lms-menu-wrap .lms-menu ul.fa-ul li .fa-stack {
  position: absolute;
  left: 5px;
  top: 16px;
  overflow: hidden;
  z-index: 2;
}

.lms-menu-wrap .lms-menu ul.fa-ul li .fa-stack-text {
  padding-left: 55px;
}

.lms-menu-wrap .lms-menu .collapse-wrap {
  background-color: #ACACAC;
  position: relative;
  margin-top: 0.5em;
}

.lms-menu-wrap .lms-menu .collapse-wrap::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 17px;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #f2f2f2;
  transform: rotate(45deg);
}

.lms-menu-wrap .lms-menu .collapse-wrap .collapse-text p.collapse-text-head {
  color: #ffffff;
  position: relative;
  padding: 1.2em 1em 0.5em 4em;
}

.lms-menu-wrap .lms-menu .collapse-wrap .collapse-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lms-menu-wrap .lms-menu .collapse-wrap .collapse-text ul li {
  margin: 0;
  position: relative;
  padding: 0.2em 0.2em 0.2em 4em;
}

.lms-menu-wrap .lms-menu .collapse-wrap .collapse-text ul li a {
  color: #ffffff;
  display: block;
  padding: 0.5em 0;
  width: 100%;
  transition: all 0.2s ease;
}

.lms-menu-wrap .lms-menu .collapse-wrap .collapse-text ul li a:hover {
  text-decoration: underline;
  color: #000000;
}

.lms-menu-wrap .lms-menu .collapse-wrap .collapse-text .mini-icon {
  position: absolute;
  left: 15px;
}

.lms-menu-wrap .final-test {
  border: 2px Solid #1422aa;
  padding: 1em 1em 1em 0;
  border-radius: 50px;
}

.lms-menu-wrap .final-test .fa-stack {
  color: #1422aa;
}

.lms-menu-wrap .final-test.bd {
  border: none !important;
}

.lms-menu-wrap .lms-menu.w-100 {
  width: 55px;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  background-color: #1422aa;
  border-color: #1422aa;
}

.list-group-item.razvitum.active, .list-group-item.razvitum.active:focus, .list-group-item.razvitum.active:hover {
  color: inherit;
  background-color: inherit;
  border-left: 5px solid #1422aa;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.list-group-item.razvitum:hover {
  color: #1422aa;
}

.carousel.carousel-dark .carousel-control {
  width: 4%;
  background-image: none;
}

.carousel.carousel-dark .carousel-indicators li {
  box-shadow: 0 0 6px 0 rgba(128, 128, 128, 0.5);
}

.carousel.carousel-light .carousel-control {
  width: 4%;
  background-image: none;
  color: #1422aa !important;
}

.carousel.carousel-light .carousel-indicators li {
  border-color: #1422aa;
  box-shadow: 0 0 6px 0 rgba(128, 128, 128, 0.5);
}

.carousel.carousel-light .carousel-indicators li.active {
  background-color: #1422aa;
}

.panel-speech {
  position: relative;
  border-color: #fecf73;
  background-color: #fecf73;
  border-radius: 15px;
}

.panel-speech .panel-body {
  background-color: transparent;
}

.panel-speech:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  bottom: -20px;
  border: 10px solid;
  border-color: #fecf73 transparent transparent #fecf73;
}

.select2-container {
  width: 100% !important;
  background: #f0f0f0 !important;
}

a {
  color: #1422aa;
}

.panel-speech-welcome {
  position: relative;
  border-color: #fecf73;
  background-color: #fecf73;
  border-radius: 15px;
}

.panel-speech-welcome .panel-body {
  background-color: transparent;
}

.panel-speech-welcome:before {
  content: " ";
  position: absolute;
  left: -30px;
  top: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20px 30px;
  border-color: transparent transparent #fecf73 transparent;
}

.inline-block {
  display: inline-block;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.h-80 {
  height: 80% !important;
}

.h-70 {
  height: 70% !important;
}

.h-60 {
  height: 60% !important;
}

.h-50 {
  height: 50% !important;
}

.h-40 {
  height: 40% !important;
}

.h-30 {
  height: 30% !important;
}

.h-20 {
  height: 20% !important;
}

.h-10 {
  height: 10% !important;
}

.p-4 {
  padding: 4em !important;
}

.p-3 {
  padding: 3em !important;
}

.p-2 {
  padding: 2em !important;
}

.p-1 {
  padding: 1em !important;
}

.p-0 {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .p-sm-1 {
    padding: 1em !important;
  }
}

@media (max-width: 768px) {
  .p-sm-2 {
    padding: 2em !important;
  }
}

@media (max-width: 768px) {
  .p-sm-3 {
    padding: 3em !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-1 {
    padding: 1em !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-2 {
    padding: 2em !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-3 {
    padding: 3em !important;
  }
}

.p-lr-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.p-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}

.p-tb-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.px-3 {
  padding-left: 3em;
  padding-right: 3em;
}

.px-4 {
  padding-left: 4em;
  padding-right: 4em;
}

.px-5 {
  padding-left: 5em;
  padding-right: 5em;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.py-4 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.py-5 {
  padding-top: 5em;
  padding-bottom: 5em;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 1em !important;
}

.pl-2 {
  padding-left: 2em !important;
}

.pl-3 {
  padding-left: 3em !important;
}

.pl-4 {
  padding-left: 4em !important;
}

.pl-5 {
  padding-left: 5em !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 1em !important;
}

.pr-2 {
  padding-right: 2em !important;
}

.pr-3 {
  padding-right: 3em !important;
}

.pr-4 {
  padding-right: 4em !important;
}

.pr-5 {
  padding-right: 5em !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1, .pv-1 {
  padding-top: 1em !important;
}

.pt-2, .pv-2 {
  padding-top: 2em !important;
}

.pt-3, .pv-3 {
  padding-top: 3em !important;
}

.pt-4, .pv-4 {
  padding-top: 4em !important;
}

.pt-5, .pv-5 {
  padding-top: 5em !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1, .pv-1 {
  padding-bottom: 1em !important;
}

.pb-2, .pv-2 {
  padding-bottom: 2em !important;
}

.pb-3, .pv-3 {
  padding-bottom: 3em !important;
}

.pb-4, .pv-4 {
  padding-bottom: 4em !important;
}

.pb-5, .pv-5 {
  padding-bottom: 5em !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-auto {
  margin: 0 auto !important;
}

.mr-1, .mx-1, .mh-1 {
  margin-right: 1em !important;
}

.mr-2, .mx-2, .mh-2 {
  margin-right: 2em !important;
}

.mr-3, .mx-3, .mh-3 {
  margin-right: 3em !important;
}

.mr-4, .mx-4, .mh-4 {
  margin-right: 4em !important;
}

.mr-5, .mx-5, .mh-5 {
  margin-right: 5em !important;
}

.ml-1, .mx-1, .mh-1 {
  margin-left: 1em !important;
}

.ml-2, .mx-2, .mh-2 {
  margin-left: 2em !important;
}

.ml-3, .mx-3, .mh-3 {
  margin-left: 3em !important;
}

.ml-4, .mx-4, .mh-4 {
  margin-left: 4em !important;
}

.ml-5, .mx-5, .mh-5 {
  margin-left: 5em !important;
}

.mt-1, .my-1, .mv-1 {
  margin-top: 1em !important;
}

.mt-2, .my-2, .mv-2 {
  margin-top: 2em !important;
}

.mt-3, .my-3, .mv-3 {
  margin-top: 3em !important;
}

.mt-4, .my-4, .mv-4 {
  margin-top: 4em !important;
}

.mt-5, .my-5, .mv-5 {
  margin-top: 5em !important;
}

.mb-1, .my-1, .mv-1 {
  margin-bottom: 1em !important;
}

.mb-2, .my-2, .mv-2 {
  margin-bottom: 2em !important;
}

.mb-3, .my-3, .mv-3 {
  margin-bottom: 3em !important;
}

.mb-4, .my-4, .mv-4 {
  margin-bottom: 4em !important;
}

.mb-5, .my-5, .mv-5 {
  margin-bottom: 5em !important;
}

.br-0 {
  border-radius: 0 !important;
}

.lh-2 {
  line-height: 2;
}

.bdt-1 {
  border-top: 1px solid #ddd;
}

.bdb-1 {
  border-bottom: 1px solid #ddd;
}

.text-primary {
  color: #1422aa !important;
}

.text-warning {
  color: #f9b233 !important;
}

.text-danger {
  color: #e4201b !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000000 !important;
}

.text-info {
  color: #a3d3e5 !important;
}

.text-success {
  color: #3fa435 !important;
}

.text-gray {
  color: #CCCCCC !important;
}

.text-red {
  color: #FF0000 !important;
}

.panel-warning {
  border-color: #f9b233 !important;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

.bg-dark {
  background-color: #666 !important;
}

.bg-cover {
  background-position: center;
  background-size: cover;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-primary {
  background-color: #1422aa !important;
  color: #FFFFFF !important;
}

.bg-gray {
  background-color: #CCCCCC !important;
}

.bg-orange {
  background-color: #f18425 !important;
}

.bg-red {
  background-color: #FF0000 !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}

.flex-row {
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.box-shadow {
  box-shadow: 0 3px 15px rgba(20, 34, 170, 0.4);
}

.shadow {
  -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  -webkit-box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
}

.shadow-2:hover {
  -webkit-box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3);
}

.border-1 {
  border: 1px solid #cccccc;
}

.no-shadow {
  box-shadow: none;
}

.title {
  font-size: 36px;
  font-weight: 100;
  margin-bottom: 55px;
  font-family: RobotoThin, sans-serif;
}

.no-border {
  border: none;
}

tr.no-border td {
  border-top: none !important;
}

.underline {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #1422aa;
}

.underline-dashed {
  border-bottom: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #1422aa;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fz-40 {
  font-size: 3.4em !important;
}

.fz-38 {
  font-size: 3.2em !important;
}

.fz-36 {
  font-size: 3em !important;
}

.fz-34 {
  font-size: 2.8em !important;
}

.fz-32 {
  font-size: 2.6em !important;
}

.fz-30 {
  font-size: 2.4em !important;
}

.fz-28 {
  font-size: 2.2em !important;
}

.fz-26 {
  font-size: 2em !important;
}

.fz-24 {
  font-size: 1.8em !important;
}

.fz-22 {
  font-size: 1.6em !important;
}

.fz-20 {
  font-size: 1.4em !important;
}

.fz-18 {
  font-size: 1.2em !important;
}

.fz-16 {
  font-size: 1em !important;
}

.fz-15 {
  font-size: 0.9em !important;
}

.fz-14 {
  font-size: 0.8em !important;
}

.fz-13 {
  font-size: 0.7em !important;
}

.fz-12 {
  font-size: 0.6em !important;
}

.fs-i {
  font-style: italic;
}

.ff-light {
  font-family: Roboto, sans-serif;
}

.ff-bold {
  font-family: RobotoBold, sans-serif;
}

.ff-black {
  font-family: RobotoBlack, sans-serif;
}

.va {
  vertical-align: middle;
}

.toggle-span-on-collapse span.show-on-collapse {
  display: none;
}

.toggle-span-on-collapse span.hide-on-collapse {
  display: inline;
}

.toggle-span-on-collapse.collapsed span.show-on-collapse {
  display: inline;
}

.toggle-span-on-collapse.collapsed span.hide-on-collapse {
  display: none;
}

.has-error .checkbox, .has-error .checkbox-inline,
.has-error .control-label, .has-error .help-block,
.has-error .radio, .has-error .radio-inline,
.has-error.checkbox label, .has-error.checkbox-inline label,
.has-error.radio label, .has-error.radio-inline label {
  color: #e4201b;
}

.alert-danger {
  color: #e4201b;
}

.has-error .form-control {
  border-color: #e4201b;
}

.no-gutters {
  margin-right: -15px;
  margin-left: -15px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.d-block {
  display: block;
}

.border-n {
  border: none !important;
}

.border-b {
  border-bottom: 1px solid #ddd !important;
}

.border-t {
  border-top: 1px solid #ddd !important;
}

.border-l {
  border-left: 1px solid #ddd !important;
}

.border-r {
  border-right: 1px solid #ddd !important;
}

.abs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.abs.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.abs.spinner span {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1em;
}

.abs.spinner span i {
  margin: 15px;
}

.abs.spinner.overlay {
  background-color: rgba(200, 200, 200, 0.5);
  z-index: 100;
}

.float-right {
  float: right;
}

.text-shadow {
  text-shadow: 2px 2px 4px #999999;
}

.text-shadow-black {
  text-shadow: 2px 2px 4px #000000;
}

.text-shadow-white {
  text-shadow: 2px 2px 4px #000000;
}

.text-bold {
  font-weight: bold;
}

.text-spacing {
  letter-spacing: 0.4em;
}

@media (max-width: 768px) {
  .text-sm-center {
    text-align: center;
  }
}

.animation-shaking {
  animation: shake 0.82s infinite ease-in-out;
  transform: translate3d(2px, 0, 0);
}

@keyframes shake {
  from {
    transform: translate3d(2px, 0, 0);
  }
  50% {
    transform: translate3d(-2px, 0, 0);
  }
  to {
    transform: translate3d(2px, 0, 0);
  }
}

.bg-warning2 {
  background-color: #f9b233;
}

.cursor-help {
  cursor: help;
}

.parent-hovershow .hovershow-inline {
  display: none;
}

.parent-hovershow .hovershow-block {
  display: none;
}

.parent-hovershow:hover .hovershow-inline {
  display: inline-block;
}

.parent-hovershow:hover .hovershow-block {
  display: block;
}

.underline-onhover:hover {
  text-decoration: underline !important;
}

.inline-middle-block {
  display: inline-block;
  vertical-align: bottom;
}

.btn-login {
  height: auto;
  border-radius: 100px;
  text-align: center;
  border: 2px solid #1422aa;
  display: block;
  line-height: 50px;
  padding: 0;
  color: #5a5a5a;
  font-size: 0.9em;
  transition: all 0.5s;
}

.btn-login:hover {
  color: #fff;
  background: #1422aa;
}

.btn-default {
  border-radius: 100px;
  border: 1px solid #ddd;
  color: #545454;
  padding: 1em 2em;
  background: #f6f6f6 !important;
  transition: all 0.25s ease;
}

.btn-default:hover {
  background: #CCCCCC !important;
}

.btn-primary {
  border-radius: 100px;
  border: none;
  color: #fff;
  padding: 1em 2em;
  background: #1422aa;
  transition: all 0.25s ease;
}

.btn-primary:hover {
  background: #1e33ff;
}

.btn-danger {
  border-radius: 100px;
  border: none;
  color: #fff;
  padding: 1em 2em;
  background: #e4201b;
  transition: all 0.25s ease;
}

.btn-danger:hover {
  background: rgba(255, 32, 27, 0.84);
}

.btn-danger-outline {
  border-radius: 100px;
  border: 1px solid #e4201b !important;
  color: #e4201b;
  background: #ffffff;
}

.btn-danger-outline:hover {
  color: #fff;
  background: #e4201b;
}

.btn-success {
  border-radius: 100px;
  border: none;
  color: #fff;
  padding: 1em 2em;
  background: #3fa435;
  transition: all 0.25s ease;
}

.btn-success:hover {
  background: #4cc63f;
}

.btn-success .btn-sm {
  padding: 5px 10px;
}

.btn-primary-outline {
  border-radius: 100px;
  border: 1px solid #1422aa !important;
  color: #1422aa;
  background: #ffffff;
}

.btn-primary-outline:hover {
  color: #fff;
  background: #1422aa;
}

.btn-link {
  color: #1422aa !important;
}

.btn-link:hover {
  text-decoration: underline;
}

.btn-warning {
  padding: 0.8em 2em;
  background-color: #fecf73;
  border-radius: 10em;
  color: #000000;
  font-size: 1em;
  border: none;
}

@media (max-width: 1200px) {
  .btn-warning {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .btn-warning {
    font-size: 14px;
  }
}

.btn-white {
  border: 2px solid #ffffff;
  border-radius: 50px;
  padding: 0.8em 2em;
  color: #ffffff;
  background-color: transparent;
  font-weight: 700;
  font-size: 1em;
  transition: all 0.3s ease;
}

.btn-white:hover {
  background-color: #ffffff;
  color: #1422aa !important;
  font-weight: 700;
}

.btn-white:focus {
  color: #ffffff;
}

.btn-white-min {
  border: 1px solid #ffffff;
  font-size: 1em;
  padding: 0.5em 2em !important;
  font-weight: 500;
}

.btn-white-min:hover {
  font-weight: 500;
}

.btn-transp {
  border-radius: 50px;
  padding: 0.8em 2em;
  color: #1422aa;
  background: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-size: 0.8em;
  text-align: center;
  transition: all 0.3s ease;
}

.btn-transp:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.8);
  color: #000000;
}

a.btn-calc {
  background-color: #f9b233;
}

.button-wrap .btn-warning {
  margin-top: 4.2em;
}

.btn-round {
  border-radius: 50% !important;
}

.btn-soc-wrap {
  text-align: center;
}

.btn-soc-wrap span {
  margin-right: 0.4em;
}

.btn-soc-enter {
  border-radius: 50%;
  padding: 0;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: 1.7em !important;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
}

.btn-soc-enter.ok {
  border: none;
  max-width: 54px;
  color: #ffffff;
  background-color: #ee8208;
}

.btn-soc-enter.ok:hover {
  color: #ffffff;
  background-color: #cd6d08;
}

.btn-soc-enter.mailru {
  border: none;
  max-width: 54px;
  color: #FF9E00;
  background-color: #005ff9;
}

.btn-soc-enter.mailru:hover {
  color: #FF9E00;
  background-color: #0054d5;
}

.btn-soc-enter.vk {
  border: none;
  max-width: 54px;
  color: #ffffff;
  background-color: #5181b8;
}

.btn-soc-enter.vk:hover {
  color: #ffffff;
  background-color: #4a6e9f;
}

.btn-soc-enter.yandex {
  border: none;
  max-width: 54px;
  color: red;
  background-color: #DDDDDD;
}

.btn-soc-enter.yandex:hover {
  color: red;
  background-color: #b6b6b6;
}

.btn-soc-enter.facebook {
  border: none;
  max-width: 54px;
  color: #ffffff;
  background-color: #4267b2;
}

.btn-soc-enter.facebook:hover {
  color: #ffffff;
  background-color: #29487d;
}

.btn-soc-enter.sberbank {
  max-width: 56px;
  color: #22A330;
  background-color: #22A330;
  border: none;
}

.btn-soc-enter.sberbank svg g path#bg {
  fill: #22A330;
}

.btn-soc-enter.sberbank svg path#c {
  fill: #FFFFFF;
}

.btn-soc-enter.sberbank:hover {
  background-color: #1f8527;
}

.btn-soc-enter.sberbank:hover svg g path#bg {
  fill: #1f8527;
}

.btn-soc-enter.viber {
  border: none;
  max-width: 54px;
  color: #ffffff;
  background-color: #574E92;
}

.btn-soc-enter.viber:hover {
  color: #ffffff;
  background-color: #41316a;
}

header .s-header {
  padding: 40px 0;
  background-color: #fff;
}

header .s-header .logo img {
  width: 70px;
  height: auto;
  float: left;
  margin-right: 10px;
}

header .s-header .logo-title {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  display: inline-block;
}

header .s-header .logo-title span {
  font-weight: 400;
  font-size: 10px;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.60em;
}

header .s-header .header-date, header .s-header .header-phone {
  font-size: 0.85em;
}

header .s-header .header-date-label, header .s-header .header-phone-label {
  color: #999999;
  font-size: 0.85em;
}

header .s-header .header-phone-number {
  margin-top: 0.3em;
}

header .s-header .header-phone-number a {
  font-size: 1.5em;
  color: #5A5A5A;
}

header .s-header .work-hours {
  font-size: 1.5em;
  margin-top: 0.3em;
  color: #5A5A5A;
}

header .s-header .btn-wrap .header-cart {
  position: absolute;
  bottom: -65%;
  left: 0;
  right: 0;
}

header .s-header .btn-wrap .header-cart a {
  color: #1422aa;
}

header .s-header .btn-wrap .header-btn {
  position: relative;
}

header .s-header .btn-wrap .header-btn .btn-group.open button {
  z-index: 1001;
}

header .s-header .btn-wrap .header-btn .btn-login i {
  color: #1422aa;
  margin-right: 10px;
  transition: all 0.3s;
}

header .s-header .btn-wrap .header-btn .btn-login:hover i {
  color: #fff;
}

header .s-header .btn-wrap .header-btn .btn-user {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1001;
  padding: 1em 1.7em 1em 0.8em;
  font-size: 0.9em;
}

header .s-header .btn-wrap .header-btn .btn-user span.caret {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}

header .s-header .btn-wrap .header-btn .dropdown-menu {
  position: absolute;
  top: 50%;
  width: 100%;
}

header .s-header .btn-wrap .header-btn .dropdown-menu li:first-child {
  padding-top: 25px;
}

header .s-header .btn-wrap .header-btn .dropdown-menu li {
  padding: 5px 0;
}

header .s-header .btn-wrap .header-btn .dropdown-menu li i {
  margin-left: 7px;
  color: #1422aa;
}

header .s-header .btn-wrap .header-btn .dropdown-menu li.divider {
  padding: 0;
  margin: 5px 0;
}

img, video {
  aspect-ratio: attr(width)/attr(height);
}

.head-logo {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  letter-spacing: 0.10em;
}

.head-logo a {
  display: inline-block;
}

.head-logo > span > span {
  font-weight: 400;
  font-size: 10px;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.63em;
  padding-left: 0.5em;
}

.menu-wrap .navbar {
  border: none;
}

.menu-wrap .navbar-default .navbar-nav > .active > a {
  background-color: transparent;
  background-image: none;
  color: #fff;
  position: relative;
}

.menu-wrap .navbar-default .navbar-nav > .active > a::after {
  content: '•';
  position: relative;
  right: -5px;
}

.menu-wrap .navbar.navbar-default {
  background-color: #fff;
  border-bottom: 1px solid #1422aa;
}

.menu-wrap .navbar {
  border-radius: 0;
  margin: 0;
}

.menu-wrap .navbar .container-fluid {
  margin: 0;
  padding: 0;
  border: none;
}

.menu-wrap .navbar .container-fluid .navbar-collapse {
  margin: 0;
  padding: 0;
}

.menu-wrap .top-menu {
  background-color: #1422aa;
}

@media only screen and (max-width: 767.98px) {
  .menu-wrap .top-menu {
    padding: 0 30px 30px 30px;
    margin-top: 60px;
  }
}

.menu-wrap .top-menu .navbar-nav > li > a {
  padding: 30px 30px 28px 0;
  font-size: 0.875em;
  line-height: 1em;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  position: relative;
}

@media only screen and (max-width: 767.98px) {
  .menu-wrap .top-menu .navbar-nav > li > a {
    padding: 30px 30px 15px 0;
  }
  .menu-wrap .top-menu .navbar-nav > li > a::before {
    display: none;
  }
}

.menu-wrap .dropdown-menu > li > a {
  padding: 10px 25px;
  transition: all 0.2s;
}

.menu-wrap .dropdown-menu {
  padding: 0;
}

.menu-wrap .navbar-default .navbar-nav > .active > a, .menu-wrap .navbar-default .navbar-nav > .open > a {
  background-image: none !important;
}

.menu-wrap .navbar-default .navbar-nav > .open > a, .menu-wrap .navbar-default .navbar-nav > .open > a:focus, .menu-wrap .navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent !important;
  color: #fff;
}

.menu-wrap .nav .open > a, .menu-wrap .nav .open > a:focus, .menu-wrap .nav .open > a:hover {
  background-color: transparent !important;
}

.menu-wrap .dropdown-menu > li > a:focus, .menu-wrap .dropdown-menu > li > a:hover {
  background-color: #a3d3e5 !important;
  background-image: none !important;
}

.menu-wrap .navbar-collapse {
  height: 100vh;
}

.menu-wrap .navbar-toggle .icon-bar {
  width: 100% !important;
}

.menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li > a:hover, .menu-wrap .buttom-menu .nav > li > a:active {
  background-color: #a3d3e5 !important;
  color: #fff !important;
}

.s-carousel {
  padding: 0;
  margin: 0;
}

.s-carousel .col-lg-12 {
  padding: 0;
}

.s-carousel .carousel {
  width: 100%;
}

.s-carousel .carousel .carousel-control {
  background-image: none;
  width: 10%;
  text-shadow: none;
}

.s-carousel .carousel .carousel-inner .item img {
  width: 100%;
  background-size: cover;
  display: block;
  background-position: center center;
}

.s-carousel .carousel .glyphicon-menu-left {
  position: absolute !important;
  top: 50%;
  right: 20%;
  transform: translateY(-50%);
  font-size: 40px;
  color: #1422aa;
}

.s-carousel .carousel .glyphicon-menu-left::after {
  position: absolute;
  content: '';
  width: 25px;
  height: 5px;
  background-color: #1422aa;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.s-carousel .carousel .glyphicon-menu-right {
  position: absolute !important;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  font-size: 40px;
  color: #1422aa;
}

.s-carousel .carousel .glyphicon-menu-right::after {
  position: absolute;
  content: '';
  width: 25px;
  height: 5px;
  background-color: #1422aa;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.s-about-center {
  padding-bottom: 50px;
}

.s-blue {
  width: 100%;
  background-color: #1422aa;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.s-blue-seminar {
  background-color: #1422aa;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding-bottom: 40px;
}

.s-blue-seminar .free-seminars {
  color: #fecf73;
  font-size: 2em;
  font-family: RobotoBold, sans-serif;
  margin-top: 1em;
  display: block;
}

.s-licenses {
  background-color: #F3F3F3;
  padding-bottom: 50px;
}

.s-licenses .licenses-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.s-licenses .licenses-wrapper .item-licenses {
  border-radius: 0;
  border: 2px solid #FFD57A;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  width: 45%;
  margin: 5px;
  background-color: #CCCCCC;
}

.s-licenses .licenses-wrapper .item-licenses:hover {
  border: 2px solid #FFD57A;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.s-licenses .licenses-wrapper .item-licenses a img {
  width: 100%;
  height: auto;
  background-size: cover;
}

.s-story {
  padding-bottom: 50px;
}

.s-story .text {
  padding-bottom: 30px;
  font-size: 18px;
}

.s-story .item-story {
  padding: 25px;
  margin: 0;
  min-height: 400px;
  height: 400px;
  border: 12px solid #fff;
  box-sizing: border-box;
  position: relative;
}

.s-story .item-story::before {
  content: '';
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.s-story .item-story .year {
  color: #1422aa;
  font-weight: 700;
}

.s-story .item-story h5 {
  font-weight: 700;
  font-size: 24px;
  color: #333333;
  padding: 15px 0;
}

.s-story .item-story p {
  font-size: 14px;
  color: #333333;
  line-height: 1.65em;
}

.s-story .bg-wrap {
  background-image: url("/img/logo.svg");
  background-size: cover;
  background-position: center left;
  width: 100%;
  height: auto;
  position: relative;
}

.s-story .bg-wrap::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
}

.s-story .bg-wrap .col-lg-3 {
  padding: 0;
}

.s-facts .container-fluid {
  padding: 0;
  margin: 0;
}

.s-facts .facts-wrap {
  background-image: url("/img/bg-foto.jpg");
  background-size: cover;
  background-position: center center;
}

.s-facts .facts-grid {
  display: grid;
  width: 100%;
  grid-gap: 1px;
  height: auto;
  grid-template-areas: "item-1 item-2 item-3 item-4" "item-5 item-2 item-6 item-7" "item-8 item-9 item-11 item-7" "item-8 item-10 item-11 item-12";
}

.s-facts .facts-grid .item-1 {
  grid-area: item-1;
}

.s-facts .facts-grid .item-1 .flipper-back {
  background-color: #f9b233;
}

.s-facts .facts-grid .item-2 {
  grid-area: item-2;
}

.s-facts .facts-grid .item-2 .flipper-back {
  background-color: #1422aa;
  color: #fff;
}

.s-facts .facts-grid .item-3 {
  grid-area: item-3;
}

.s-facts .facts-grid .item-3 .flipper-back {
  background-color: #a3d3e5;
}

.s-facts .facts-grid .item-4 {
  grid-area: item-4;
}

.s-facts .facts-grid .item-4 .flipper-back {
  background-color: #f9b233;
}

.s-facts .facts-grid .item-5 {
  grid-area: item-5;
}

.s-facts .facts-grid .item-5 .flipper-back {
  background-color: #a3d3e5;
}

.s-facts .facts-grid .item-6 {
  grid-area: item-6;
}

.s-facts .facts-grid .item-6 .flipper-back {
  background-color: #f9b233;
}

.s-facts .facts-grid .item-7 {
  grid-area: item-7;
}

.s-facts .facts-grid .item-7 .flipper-back {
  background-color: #1422aa;
  color: #fff;
}

.s-facts .facts-grid .item-8 {
  grid-area: item-8;
}

.s-facts .facts-grid .item-8 .flipper-back {
  background-color: #1422aa;
  color: #fff;
}

.s-facts .facts-grid .item-9 {
  grid-area: item-9;
}

.s-facts .facts-grid .item-9 .flipper-back {
  background-color: #a3d3e5;
}

.s-facts .facts-grid .item-10 {
  grid-area: item-10;
}

.s-facts .facts-grid .item-10 .flipper-back {
  background-color: #f9b233;
}

.s-facts .facts-grid .item-11 {
  grid-area: item-11;
}

.s-facts .facts-grid .item-11 .flipper-back {
  background-color: #1422aa;
  color: #fff;
}

.s-facts .facts-grid .item-12 {
  grid-area: item-12;
}

.s-facts .facts-grid .item-12 .flipper-back {
  background-color: #a3d3e5;
}

.s-facts .facts-grid span {
  font-size: 40px;
  display: block;
  color: #fff;
  font-weight: 500;
}

.s-facts .facts-grid .item {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  perspective: 800px;
  cursor: pointer;
}

.s-facts .facts-grid .item::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
}

.s-facts .facts-grid .item .flipper-card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 2px solid #fff;
}

.s-facts .facts-grid .item .flipper-card .flipper-back {
  position: relative;
  padding: 30px 50px;
  width: 100%;
  height: 100%;
  display: block;
  transform: rotateY(180deg);
}

.s-facts .facts-grid .item .flipper-card .flipper-front,
.s-facts .facts-grid .item .flipper-card .flipper-back {
  backface-visibility: hidden;
}

.flipper-card.flipper-is-flipped {
  transform: rotateY(180deg);
}

footer {
  background-color: #1422aa;
  border-top: 1px solid #999;
  padding-top: 40px;
  position: relative;
  font-size: 14px;
}

footer .footer_up {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  position: relative;
  min-height: 60px;
}

footer .footer_nav {
  padding-bottom: 20px;
  position: relative;
  min-height: 60px;
}

footer .footer_nav-header a {
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

footer .footer_nav-header p {
  color: #fff;
}

footer .footer_nav i {
  position: absolute;
  left: -15px;
}

footer .footer_nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

footer .footer_nav ul li {
  padding: 6px 0;
  line-height: 1.2em;
}

footer .footer_nav ul li a {
  color: #cccccc;
  text-decoration: none;
}

footer .footer_nav ul li a:hover {
  color: #fff;
}

footer .up-arrow {
  display: block;
  position: absolute;
  top: 0;
  right: 28px;
}

footer .footer_nav .up-arrow a {
  transition: opacity 0.3s;
}

footer .footer-title {
  color: #fff;
}

footer .footer-bottom {
  padding-top: 50px;
  color: #fff;
}

footer .footer-bottom .footer-title {
  font-weight: 700;
  color: #fff;
}

footer .footer-bottom .phone {
  font-size: 34px;
  font-family: RobotoLight, sans-serif;
  line-height: 100%;
  vertical-align: top;
  padding-bottom: 10px;
}

footer .footer-bottom .phone a {
  text-decoration: none;
  color: #fff;
}

footer .footer-bottom .call a {
  color: #fff;
}

footer .footer-bottom .footer-links {
  padding-bottom: 0px;
}

footer .footer-bottom .footer-links ul {
  list-style: none;
  padding-left: 0px;
  padding-top: 0px;
}

footer .footer-bottom .footer-links ul li {
  display: inline-block;
  margin-right: 20px;
}

footer .footer-bottom .footer-social-links ul {
  padding-left: 0px;
}

footer .footer-bottom .footer-social-links ul li {
  display: table-cell;
  vertical-align: middle;
}

footer .footer-bottom .footer-social-links ul li span {
  margin-right: 0.7em;
}

footer .footer-bottom .footer-links ul li a {
  color: #fff;
}

footer .footer-bottom .usage {
  font-size: 11px;
}

footer .footer-bottom .copy {
  text-align: right;
}

ul.nav-tabs-razvitum {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;
}

ul.nav-tabs-razvitum li a {
  height: 40px;
  display: block;
  line-height: 40px;
  color: #999999 !important;
  font-size: 16px;
  border-bottom: 2px solid #999999;
  padding: 0;
}

ul.nav-tabs-razvitum li a:hover {
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-color: #f9b233;
}

ul.nav-tabs-razvitum li a:focus {
  background-color: transparent;
}

ul.nav-tabs-razvitum li.active a {
  color: #1422aa !important;
  border-bottom: 3px solid #1422aa;
}

.nav-tabs-razvitum {
  margin-bottom: 45px;
}

ul.nav-tabs-razvitum li {
  display: inline-block;
  vertical-align: top;
  margin-right: 29px;
  margin-bottom: 20px;
}

.input-group-razvitum .form-control {
  display: block;
  width: 100%;
  height: 46px;
  padding-top: 10px;
  padding-right: 1.5em;
  padding-left: 1.5em;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 1.3333333;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  box-shadow: inset 0px 3px 9px 0px rgba(16, 17, 21, 0.14) !important;
  z-index: 10;
}

.input-group-razvitum .form-control:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-razvitum .input-group-btn .btn {
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 25px;
}

.input-group-razvitum .input-group-btn .btn:first-child {
  margin-left: -1.5em;
  z-index: 11;
}

ul.sorting-pills li a {
  color: #003399;
}

ul.sorting-pills li a:hover {
  color: #000000;
  background-color: transparent;
  text-decoration: underline;
}

ul.sorting-pills li.active a {
  color: #000000;
  background-color: transparent;
}

ul.sorting-pills li.active a:hover, ul.sorting-pills li.active a:focus {
  color: #000000;
  background-color: transparent;
  text-decoration: underline;
}

.sorting-header {
  padding: 10px 15px;
  display: inline-block;
  font-size: 1em;
  float: left;
  margin: 0;
  line-height: 1.42857143;
  color: #999999;
}

.positions-bg {
  background-color: #f4fdfd;
}

.text-columns-2 {
  column-count: 2;
  column-gap: 2em;
}

.text-columns-5 {
  column-count: 5;
  column-gap: 3em;
}

.column-block {
  break-inside: avoid-column;
  page-break-inside: avoid;
}

.programs__card {
  margin-bottom: 30px;
}

.programs__card .programs__card-block {
  position: relative;
}

.programs__card .programs__card-block:hover .programs__card-main {
  background-size: 105%;
}

.programs__card .programs__card-block:hover .programs__card-main::before {
  background: rgba(0, 0, 0, 0.6) !important;
  z-index: 0;
}

.programs__card .programs__card-block .programs__card-main {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  color: #fff;
  height: auto;
  padding: 25px 65px 65px 30px;
  position: relative;
  transition: background-size 0.3s linear;
}

.programs__card .programs__card-block .programs__card-main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  z-index: 0;
  transition: all 0.3s ease;
}

.programs__card .programs__card-block .programs__card-main .programs__item-type {
  position: absolute;
  left: 30px;
  margin: 0;
  bottom: 24px;
  z-index: 5;
  color: #fff;
}

.programs__card .programs__card-block .programs__card-main .programs__item-type a {
  color: #fff !important;
}

.programs__card .programs__card-block .programs__card-main .programs__item-type a:hover {
  color: #a3d3e5 !important;
}

.programs__card .programs__card-block .programs__card-main.v1::before {
  background: rgba(0, 0, 0, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v2::before {
  background: rgba(20, 0, 0, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v3::before {
  background: rgba(0, 20, 0, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v4::before {
  background: rgba(0, 0, 20, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v5::before {
  background: rgba(0, 20, 20, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v6::before {
  background: rgba(20, 0, 20, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v7::before {
  background: rgba(20, 20, 0, 0.5);
}

.programs__card .programs__card-block .programs__card-main.v8::before {
  background: rgba(20, 20, 20, 0.5);
}

.programs__card .programs__card-block .programs__card-main .programs__card-content {
  position: relative;
}

.programs__card .programs__card-block .programs__card-main .programs__card-content .programs__item-topic {
  font-size: 14px;
  margin-bottom: 25px;
}

.programs__card .programs__card-block .programs__card-main .programs__card-content .programs__item-topic a {
  color: #fff;
  transition: all 0.25s;
}

.programs__card .programs__card-block .programs__card-main .programs__card-content .programs__item-topic a:hover {
  color: #a3d3e5;
}

.programs__card .programs__card-block .programs__card-main .programs__card-content .programs__item-title {
  font-size: 18px;
  text-transform: uppercase;
}

.programs__card .programs__card-block .programs__card-main .programs__card-content .programs__item-title a {
  color: #fff;
  transition: all 0.25s;
}

.programs__card .programs__card-block .programs__card-main .programs__card-content .programs__item-title a:hover {
  color: #a3d3e5;
}

.programs__card .programs__card-block .programs__card-main .programs__card-star {
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  color: #fff;
  top: 15px;
  right: 15px;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  z-index: 5;
  font-weight: 700;
}

.programs__card .programs__card-block .programs__card-main .programs__card-star::before {
  content: '\f005';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  position: absolute;
  top: -3px;
  left: -2px;
  width: 100%;
  height: 100%;
  font-size: 40px;
  color: #f9b233;
  z-index: -1;
}

.programs__card .programs__card-block .programs__card-bottom {
  padding: 30px;
  background: #fff;
}

.programs__card .programs__card-block .programs__card-bottom .programs__card-info {
  margin-bottom: 18px;
  font-size: 0;
}

.programs__card .programs__card-block .programs__card-bottom .programs__card-info-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
  margin-bottom: 12px;
  white-space: nowrap;
  color: #5a5a5a;
  font-size: 14px;
}

.programs__card .programs__card-block .programs__card-bottom .programs__card-info-item:last-child {
  margin-right: 0 !important;
}

.programs__card .programs__card-block .programs__card-bottom .programs__card-info-item i {
  color: #f9b233;
  font-size: 12px;
}

.panel.panel-outline {
  background-color: rgba(0, 0, 0, 0.3);
  border-width: 4px;
  border-radius: 15px;
}

.program-calculate .panel-body {
  background-color: #fecf73;
  border: none;
}

.program-calculate a.btn-calculate {
  padding: 0.8em 1.5em;
  background-color: #fecf73;
  color: #000000;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  font-size: 1em;
}

.program-calculate a.btn-calculate i {
  margin-right: 7px;
}

.program .media {
  margin-top: 2em;
}

.jumbotron.program-cover {
  position: relative;
  color: #fff;
  margin-bottom: 0;
  overflow: hidden;
}

.jumbotron.program-cover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.jumbotron.program-cover div.program-cover {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: 8;
}

.jumbotron.program-cover .container {
  position: relative;
  z-index: 10;
}

.jumbotron.program-cover h1 {
  font-size: 2.5em;
  font-family: RobotoBold, sans-serif;
}

@media (max-width: 400px) {
  .jumbotron.program-cover h1 {
    font-size: 25px;
  }
}

.jumbotron.program-cover p.small {
  font-size: 85%;
}

.jumbotron .program-head .discount-ribbon {
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  position: relative;
  background: red;
  color: white;
  display: inline-block;
  left: -15px;
}

.jumbotron .program-head .discount-ribbon:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 15px solid red;
}

.jumbotron .program-head .discount-ribbon-v {
  position: relative;
  top: -48px;
  left: 0;
  color: white;
  display: inline-block;
}

@media (max-width: 767px) {
  .jumbotron .program-head .discount-ribbon-v {
    top: -30px;
  }
}

.jumbotron .program-head .discount-ribbon-v:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 75px solid transparent;
  border-right: 75px solid transparent;
  border-top: 10px solid red;
}

.jumbotron .program-head .discount-ribbon-v span {
  position: relative;
  display: block;
  text-align: center;
  background: red;
  font-size: 14px;
  line-height: 1;
  padding: 15px;
  width: 150px;
}

.jumbotron .program-head .discount-ribbon-v span:before, .jumbotron .program-head .discount-ribbon-v span:after {
  position: absolute;
  content: "";
}

.jumbotron .program-head .star {
  position: absolute;
  margin: 50px 0 0 140px;
}

.jumbotron .program-head .star i {
  color: #FED073;
}

.jumbotron .program-head .star span {
  font-size: 70px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.timeline {
  display: flex;
  align-items: center;
  height: 100px;
}

.event_point {
  position: relative;
}

.event_bubble {
  position: absolute;
  width: max-content;
  height: 60px;
  top: -30px;
  left: -15px;
}

.event_bubble:after, .event_bubble:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-bottom: 0;
}

.eventTime {
  display: flex;
}

.DayDigit {
  font-size: 16px;
  margin-left: 10px;
  color: #f9b233;
}

.DayDigit_first {
  font-size: 16px;
  margin-left: 10px;
}

.time {
  position: absolute;
  width: max-content;
  font-size: 14px;
  margin-top: -3px;
  margin-left: -5px;
}

.razvitum-ordered-list ol, .razvitum-ordered-list ul {
  list-style-type: none;
  margin-left: 2em;
  padding-left: 0;
}

.razvitum-ordered-list ol > li, .razvitum-ordered-list ul > li {
  counter-increment: customlistcounter;
  margin-bottom: 1em;
}

.razvitum-ordered-list ol > li:before, .razvitum-ordered-list ul > li:before {
  content: counter(customlistcounter) " ";
  font-weight: bold;
  font-size: 150%;
  color: #f9b233;
  float: left;
  width: 2em;
}

.razvitum-ordered-list ol:first-child, .razvitum-ordered-list ul:first-child {
  counter-reset: customlistcounter;
}

.panel-calc {
  margin-bottom: 0;
  border-radius: 0;
  border-color: #f9b233;
  border-width: 3px;
  background-color: #f9b233;
}

.panel-calc input {
  line-height: normal !important;
}

.btn-calc {
  border-width: 0;
  border-radius: 0 0 30px 30px;
  background-color: #f9b233;
}

.center-columns {
  text-align: center;
}

.center-columns > div {
  float: none;
  margin: 0 auto;
  display: inline-block;
  margin-right: -4px;
}

.panel-price {
  border-style: dashed;
  border-radius: 0;
  border-width: 2px;
}

.panel-price mark {
  line-height: 150%;
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background: none;
  background-image: linear-gradient(-100deg, rgba(238, 162, 54, 0.15), rgba(238, 162, 54, 0.8) 100%, rgba(238, 162, 54, 0.25));
  -webkit-box-shadow: inset 0px 0px 15px 5px white;
  box-shadow: inset 0px 0px 15px 5px white;
}

.author-card {
  min-width: 230px;
}

.author-card a:hover {
  text-decoration: none;
}

.author-card h3 {
  color: #1422aa;
  font-weight: normal;
}

.author-card .thumbnail {
  border-radius: 0;
  padding: 0;
}

.author-card .thumbnail .author-card-title {
  background-color: #f2f2f2;
  padding-top: 15px;
  padding-bottom: 15px;
}

.author-card .thumbnail img {
  image-rendering: optimizeQuality;
}

.suggestions-wrapper {
  display: block !important;
}

.cart-modal-item {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  padding-bottom: 15px;
}

.cart-modal-item .btn-link {
  color: #999999 !important;
  text-decoration: underline;
}

.cart-modal-item .form-control {
  padding: 0.3em;
  text-align: center;
}

.cart-modal-item .input-group .btn {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
}

.cart-modal-item .input-group-btn {
  width: 36px;
  height: 36px;
}

.cart-modal-item .input-group-btn button {
  position: relative;
}

.cart-modal-item .input-group-btn button i {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cart-modal-item .input-lg {
  font-size: 18px;
}

.cart-modal-item .sub_total {
  font-size: 18px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 36px;
}

.cart-modal-item .btn-default {
  margin-bottom: 10px;
}

.alert-trash-modal {
  border: 2px solid red;
  border-radius: 15px;
  padding-left: 20px;
  padding-top: 10px;
}

.alert-trash-modal p {
  margin-left: 10px;
  padding-left: 5px;
}

.alert-trash-modal i {
  margin-left: -10px;
  margin-top: 5px;
  float: left;
  color: red;
}

.cart-promocode label {
  font-weight: normal;
  margin-top: 1em;
}

.modal-footer .btn + .btn {
  margin-bottom: 10px;
}

.cart-item {
  border-top: 2px solid #f2f2f2;
  margin-top: 2em;
}

.cart-item .cart-listeners .counter-listeners li {
  position: relative;
}

.cart-item .cart-listeners .counter-listeners .btn-link {
  color: #999999 !important;
}

.cart-item .cart-program > p {
  padding-left: 40px;
}

.cart-item .cart-program .text-primary {
  position: relative;
  padding-left: 40px;
}

.cart-item .cart-program .text-primary span {
  position: absolute;
  top: 0;
  left: 0;
}

.request-wrap {
  position: relative;
}

.request-wrap .request-content-head {
  position: relative;
}

.request-wrap .left-aside-bar {
  margin: 0;
}

.request-wrap .left-aside-bar h4 {
  font-weight: 700;
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 10px 5px 10px 15px;
  margin: 30px 0 0 0;
  position: relative;
}

.request-wrap .left-aside-bar h4:first-child {
  margin: 10px 0 0 0;
}

.request-wrap .left-aside-bar a.list-group-item {
  border: none;
  background-color: transparent;
  position: relative;
  transition: all 0.2s linear;
  color: #666666;
  padding-right: 27px;
}

.request-wrap .left-aside-bar a.list-group-item:hover {
  background-color: #a3d3e5;
  color: #fff;
}

.request-wrap .left-aside-bar a.list-group-item span.badge {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  line-height: 22px;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 8px;
  background-color: #1422aa;
  top: 50%;
  transform: translateY(-50%);
}

.request-wrap .left-aside-bar a.list-group-item.active {
  color: #000000;
  background-color: #a3d3e5;
}

.request-wrap .left-aside-bar a.list-group-item.active span.badge {
  color: #fff;
}

.request-wrap .left-aside-bar a.list-group-item.active:hover {
  color: #fff;
}

.quick-registration {
  padding-top: 50px;
  padding-bottom: 50px;
}

.quick-registration ul {
  margin: 25px 0;
  padding-left: 15px;
}

.quick-registration a.btn-link {
  padding: 0;
}

.quick-registration button.btn-primary {
  margin-top: 25px;
}

.quick-registration label {
  font-style: italic;
}

.modal-user-login .modal-header {
  border-bottom: none;
}

.modal-user-login a {
  font-size: inherit;
}

.vacancies-wrap .desc, .teachers-wrap .desc, .team-wrap .desc {
  font-size: 18px;
}

.vacancies-wrap ol, .teachers-wrap ol, .team-wrap ol {
  padding-left: 15px;
}

.vacancies-wrap ol li, .teachers-wrap ol li, .team-wrap ol li {
  line-height: 1.8;
}

.vacancies-wrap .item-vacancies, .teachers-wrap .item-vacancies, .team-wrap .item-vacancies {
  width: 100%;
  margin-bottom: 30px;
}

.vacancies-wrap .item-vacancies img, .teachers-wrap .item-vacancies img, .team-wrap .item-vacancies img {
  width: 40%;
}

.vacancies-wrap .item-vacancies_h, .teachers-wrap .item-vacancies_h, .team-wrap .item-vacancies_h {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  font-family: Arial, sans-serif;
  padding: 10px 0;
}

.vacancies-wrap .item-vacancies p, .teachers-wrap .item-vacancies p, .team-wrap .item-vacancies p {
  line-height: 1.8;
}

.vacancies-wrap .item-teachers, .vacancies-wrap .item-team, .teachers-wrap .item-teachers, .teachers-wrap .item-team, .team-wrap .item-teachers, .team-wrap .item-team {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.vacancies-wrap .item-teachers_img, .vacancies-wrap .item-team_img, .teachers-wrap .item-teachers_img, .teachers-wrap .item-team_img, .team-wrap .item-teachers_img, .team-wrap .item-team_img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 2px solid #cccccc;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.vacancies-wrap .item-teachers_img img, .vacancies-wrap .item-team_img img, .teachers-wrap .item-teachers_img img, .teachers-wrap .item-team_img img, .team-wrap .item-teachers_img img, .team-wrap .item-team_img img {
  z-index: 1;
  width: 100%;
  height: auto;
}

.vacancies-wrap .item-teachers_img i, .vacancies-wrap .item-team_img i, .teachers-wrap .item-teachers_img i, .teachers-wrap .item-team_img i, .team-wrap .item-teachers_img i, .team-wrap .item-team_img i {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  color: #cccccc;
}

.concept-wrap .concept-data {
  color: #1422aa;
}

.concept-wrap .concept-data span {
  font-size: 6em;
}

.concept-wrap .concept-data p {
  font-size: 18px;
}

.concept-wrap .item-concept {
  text-align: center;
}

.concept-wrap .item-concept img {
  width: 35%;
  height: auto;
}

.concept-wrap .column {
  padding-top: 20px;
  padding-bottom: 20px;
  column-width: fill-available;
  column-count: 2;
  column-gap: 10%;
}

.concept-wrap .column ol {
  padding-left: 15px;
  line-height: 1.9;
}

.concept-wrap .init {
  padding: 0px 0 0px 30px;
  margin: 30px 0;
  position: relative;
}

.concept-wrap .blue::before {
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1422aa;
}

.concept-wrap .light-blue::before {
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #a3d3e5;
}

.concept-wrap .light-orang::before {
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fecf73;
}

.teacher-wrap {
  padding: 40px 15px;
}

.teacher-wrap h1 {
  padding-bottom: 20px;
}

.teacher-wrap .right-column .position p {
  font-weight: 700;
  font-size: 15px;
}

.teacher-wrap .right-column h5 {
  color: #1422aa;
  font-weight: 700;
  padding-top: 20px;
  font-size: 15px;
}

.teacher-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.teacher-wrap ul li {
  line-height: 1.8;
}

.teacher-wrap .left-column {
  font-size: 16px;
  margin: 0 15px 15px 15px;
}

.teacher-wrap .left-column .avatar-wrap {
  width: 250px;
  height: 250px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.teacher-wrap .left-column .avatar-wrap img {
  box-shadow: 0 0 0 20px #f2f2f2;
  margin: auto;
}

.structure-wrap .item-structure {
  width: 100%;
  margin-bottom: 30px;
}

.structure-wrap .item-structure img {
  width: 40%;
}

.structure-wrap .item-structure_h {
  padding: 10px 0;
  font-family: Roboto, sans-serif;
  font-size: 24px;
}

.structure-wrap .item-structure ul {
  padding-left: 15px;
}

.tabletree {
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
}

.tabletree ul {
  position: relative;
  display: table-cell;
  margin: 0 !important;
  padding: 0 0 0 60px !important;
  vertical-align: middle;
}

.tabletree li {
  display: table;
  position: relative;
  margin: 0 !important;
  padding: 0 0 0 58px !important;
}

.tabletree div {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: auto;
  height: 180px;
}

.tabletree ul:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 50%;
  border-bottom: 2px solid #999;
}

.tabletree ul li:after, .tabletree ul li:not(:last-child):not(:first-child):before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 50%;
  border-bottom: 2px solid #999;
}

.tabletree ul:before {
  left: 0;
}

.tabletree ul li:not(:only-child):after {
  border-left: 2px solid #999;
}

.tabletree ul li:not(:only-child):first-child:after {
  top: 54%;
  border-top: 2px solid #999;
  border-bottom-color: transparent;
}

.tabletree ul li:not(:last-child):not(:first-child):before {
  height: 101%;
  border-left: 2px solid #999;
  border-bottom-color: transparent;
}

.tabletree span {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #999999;
  border-radius: 50%;
  position: relative;
  margin: 0;
  overflow: hidden;
}

.tabletree p {
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  padding-top: 55px;
  font-size: 12px;
  width: 200px;
  left: -50%;
}

.tabletree p strong {
  color: #1422aa;
}

.s-color {
  padding: 20px 0;
}

.s-color .item-color {
  margin-bottom: 10px;
  margin-right: 10px;
}

.s-color .item-color .blue, .s-color .item-color .green, .s-color .item-color .orang {
  position: relative;
  padding-top: 45px;
}

.s-color .item-color .blue::before, .s-color .item-color .green::before, .s-color .item-color .orang::before {
  content: '';
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
}

.s-color .item-color .blue {
  color: #1422aa;
}

.s-color .item-color .blue::before {
  background-color: #1422aa;
}

.s-color .item-color .green {
  color: #3fa435;
}

.s-color .item-color .green::before {
  background-color: #3fa435;
}

.s-color .item-color .orang {
  color: #f18425;
}

.s-color .item-color .orang::before {
  background-color: #f18425;
}

.s-color .item-color-h {
  font-size: 22px;
  font-family: Roboto, sans-serif;
  line-height: 1.2;
}

.s-color .item-color ul {
  padding-left: 15px;
}

.s-numbers {
  padding: 20px 0;
}

.s-numbers .item-numb {
  color: #1422aa;
  margin: 0 10px;
}

.s-numbers .item-numb-h {
  font-size: 4.5em;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.s-numbers .item-numb-text {
  font-size: 16px;
}

.s-advant .item-advant {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.s-advant .item-advant-img {
  min-width: 80px;
  width: 80px;
  min-height: 80px;
  height: 80px;
  margin-right: 15px;
}

.s-advant .item-advant-img img {
  width: 100%;
  height: auto;
}

.s-feature .item-feature {
  margin-bottom: 15px;
}

.s-feature .item-feature img {
  width: 60px;
  height: auto;
}

.s-feature .color {
  text-transform: uppercase;
  color: #a3d3e5;
  padding-top: 0;
}

.s-feature i {
  color: #a3d3e5;
  font-size: 40px;
  margin: 0;
  padding: 0;
}

.s-feature-2 .item-feature {
  margin-bottom: 15px;
}

.s-feature-2 .item-feature img {
  width: 60px;
  height: auto;
}

.s-feature-2 .item-feature .color {
  text-transform: uppercase;
  color: #a3d3e5;
  padding-top: 0;
}

.s-feature-2 .item-feature-icon {
  display: flex;
  align-items: center;
}

.s-feature-2 .item-feature-icon i {
  color: #a3d3e5;
  font-size: 40px;
  margin-right: 10px;
  padding: 0;
}

.s-loyalty {
  position: relative;
}

.s-loyalty .item-loyalty {
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.s-loyalty .item-loyalty span {
  position: absolute;
  top: 6%;
  left: 8%;
  display: block;
  font-size: 2em;
  font-family: Roboto, sans-serif;
  color: #1422aa;
  font-weight: 700;
}

.сontent-wrap .s-saying {
  padding: 10px;
}

.сontent-wrap .img-wrap {
  text-align: center;
  background-color: #cccccc;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
}

.сontent-wrap .img-wrap img {
  display: inline-block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.сontent-wrap .filter-wrap {
  border: 1px solid #000;
  padding: 15px;
}

.сontent-wrap .pay-wrap {
  display: flex;
  justify-content: flex-start;
}

.сontent-wrap .pay-wrap .item-pay {
  text-align: center;
  width: 25%;
}

.сontent-wrap .pay-wrap .item-pay img {
  width: auto;
  height: 100px;
  display: inline-block;
}

.s-beruza {
  color: #fff;
}

.s-beruza .p1 {
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.s-beruza .p2 {
  font-size: 36px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.s-beruza .p3 {
  font-size: 18px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.profile-wrap, .orders-wrap, .bonus-account-wrap, .study-wrap, .statistics-wrap {
  padding-top: 40px;
  padding-bottom: 40px;
}

.profile-wrap .points {
  color: #1422aa;
  font-weight: 700;
  font-size: 26px;
}

.profile-wrap .profile-img {
  height: 250px;
  width: 250px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  border: 1px solid #ddd;
}

.profile-wrap .profile-img:hover span {
  display: inline-block;
  border-radius: 12px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 15px;
  left: 50%;
  font-size: 0.9em;
  transform: translateX(-50%);
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease;
}

.profile-wrap .profile-img:hover span label {
  cursor: pointer;
}

.profile-wrap table.data-profile {
  width: 100%;
}

.profile-wrap table.data-profile tr td {
  border: none;
}

.profile-wrap table.document-status tr td {
  border-bottom: 1px solid #ddd;
  padding-left: 0;
}

.profile-wrap .item-document {
  margin-bottom: 40px;
}

.profile-wrap .item-document img {
  cursor: pointer;
}

.profile-wrap .item-document a {
  padding: 0;
  margin-right: 10px;
  text-decoration: underline;
  color: #cccccc;
}

.orders-wrap .panel-body {
  padding: 15px 0;
  margin: 20px 0 40px 0;
  border-top: 2px solid #cccccc;
  overflow: hidden;
}

.orders-wrap span {
  color: #a3d3e5;
}

.orders-wrap span.number-order {
  font-size: 20px;
  font-weight: 700;
  color: #1422aa;
}

.orders-wrap .row:first-child {
  padding: 0 0 5px 0;
}

.orders-wrap .orders-desc a {
  text-decoration: none;
  position: relative;
  padding-right: 15px;
}

.orders-wrap .orders-desc a i {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.3s linear;
}

.orders-wrap .orders-desc a.collapsed {
  position: relative;
}

.orders-wrap .orders-desc a.collapsed i {
  transform: translateY(-50%) rotate(0deg);
}

.bonus-account-wrap .block-blue {
  border: 2px solid #1422aa;
  position: relative;
  text-align: center;
  padding: 10px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bonus-account-wrap .block-blue span {
  position: absolute;
  top: 3%;
  left: 3%;
}

.bonus-account-wrap .block-blue p {
  padding: 0;
  margin: 0;
}

.bonus-account-wrap .block-blue a.btn {
  padding: 0;
}

.bonus-account-wrap .block-blue a.btn:hover {
  text-decoration: underline;
}

.bonus-account-wrap .block-blue .numb {
  font-size: 36px;
  font-weight: 700;
  color: #1422aa;
  padding: 0;
  margin: 0;
}

.bonus-account-wrap table.history-bonus thead {
  border-bottom: 1px solid #ddd;
}

.bonus-account-wrap table.history-bonus tr td {
  border: none;
}

.study-wrap span.number-order {
  font-size: 20px;
  font-weight: 700;
  color: #1422aa;
}

.study-wrap .panel-body .row {
  border-bottom: 1px solid #cccccc;
  padding-top: 15px;
  padding-bottom: 15px;
}

.study-wrap .panel-body .row:first-child {
  padding: 0 0 5px 0;
}

.minors-wrap {
  padding-top: 40px;
  padding-bottom: 40px;
}

.minors-wrap .item-minor {
  box-shadow: 0 5px 15px rgba(204, 204, 204, 0.5);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.minors-wrap .item-minor:hover .item-minor-top .img {
  transform: scale(1.03);
}

.minors-wrap .item-minor:hover .item-minor-top .img::before {
  background: rgba(0, 0, 0, 0.8);
}

.minors-wrap .item-minor-top {
  position: relative;
  padding: 25px;
  overflow: hidden;
  color: #fff;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.minors-wrap .item-minor-top h4 {
  text-transform: uppercase;
  line-height: 24px;
  font-family: Arial, sans-serif;
  flex-grow: 1;
}

.minors-wrap .item-minor-top .star {
  position: absolute;
  top: 15px;
  right: 15px;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  z-index: 1;
  font-size: 12px;
}

.minors-wrap .item-minor-top .star::before {
  position: absolute;
  top: -1px;
  right: 0;
  content: '\f005';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  color: #f9b233;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-size: 36px;
}

.minors-wrap .item-minor-top .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.3s ease;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.minors-wrap .item-minor-top .img::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
}

.minors-wrap .item-minor-top .minor-time {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.minors-wrap .item-minor-top .minor-time .time {
  position: relative;
  padding-left: 15px;
}

.minors-wrap .item-minor-bottom {
  padding: 20px 25px;
  position: relative;
}

.minors-wrap .item-minor-bottom .stars i {
  color: #f9b233 !important;
  font-size: 10px;
}

.minors-wrap .item-minor-bottom-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.minors-wrap .item-minor-bottom-flex .left {
  flex: auto;
}

.minors-wrap .item-minor-bottom-flex .right {
  flex: auto;
}

.minors-wrap .item-minor-footer {
  padding: 0 25px 20px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 1199.98px) {
  .minors-wrap .item-minor-footer {
    font-size: 1em;
  }
}

@media only screen and (max-width: 991.98px) {
  .minors-wrap .item-minor-footer {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 414.98px) {
  .minors-wrap .item-minor-footer {
    font-size: 0.9em;
  }
}

.minors-wrap .item-minor_banner {
  background-color: #73C4C4;
  background-image: url("/img/b/baloons1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.minors-wrap .item-minor_banner img {
  transition: all .1s ease-in-out;
}

.minors-wrap .item-minor_banner:hover img {
  transform: scale(1.05);
}

.minors-wrap .item-minor_banner-content {
  flex-grow: 1;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}

.statistics-wrap span.number-order {
  font-size: 20px;
  font-weight: 700;
  color: #1422aa;
}

.statistics-wrap .row p {
  margin: 0;
}

.statistics-wrap table.exam-wrap tr td {
  border: none;
}

.statistics-wrap i.far.fa-question-circle {
  color: #cccccc;
  margin-left: 5px;
}

.statistics-wrap i.fas.fa-question-circle {
  color: #1422aa;
}

.statistics-wrap .progress {
  height: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.statistics-wrap .progress .progress-bar {
  background-color: #fecf73;
  color: #1422aa;
  text-align: right;
  padding-right: 15px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 700;
  box-shadow: none;
}

.statistics-wrap .exam-wrap .block-exam {
  padding: 15px;
  box-shadow: 0 0 10px rgba(20, 34, 170, 0.3);
  overflow: hidden;
}

.statistics-wrap .exam-wrap .block-exam-text {
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
}

.statistics-wrap .exam-wrap .block-exam strong {
  font-size: 14px;
}

.statistics-wrap .exam-wrap .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.statistics-wrap .exam-wrap .block-icon i {
  font-size: 20px;
}

.statistics-wrap .statistics-data {
  border: 2px solid #1422aa;
  padding: 10px;
}

.statistics-wrap .statistics-data-numb {
  font-size: 30px;
  font-weight: 700;
  color: #1422aa;
  text-align: center;
  padding: 10px 0;
}

.statistics-wrap .statistics-journal {
  padding: 10px;
  border: 1px solid #999;
  border-top: none;
}

.statistics-wrap .statistics-info {
  font-size: 16px;
  padding: 20px 10px 20px 35px;
  border: 1px solid #999;
  border-top: none;
  position: relative;
}

.statistics-wrap .statistics-info span {
  position: absolute;
  top: 10px;
  left: 10px;
}

.statistics-wrap .statistics-info span i {
  font-size: 22px;
}

.statistics-wrap .statistics-info .phone {
  font-size: 1.2em;
}

.statistics-wrap .statistics-info .mail {
  font-size: 1.3em;
}

.statistics-wrap .table-accordion .panel-group .panel + .panel {
  margin: 0;
}

.statistics-wrap .table-accordion .head-table {
  background-color: #1422aa;
  color: #fff;
  font-weight: 700;
  padding: 10px 15px;
  border: none;
}

.statistics-wrap .table-accordion .head-table .fa-question-circle {
  color: #fff;
}

.statistics-wrap .table-accordion .panel-heading {
  background-color: #fff;
}

.statistics-wrap .table-accordion .panel-title {
  font-size: 14px !important;
  text-decoration: underline;
}

.statistics-wrap .table-accordion .panel-title:hover {
  color: #1422aa;
}

.statistics-wrap .table-accordion .panel-default {
  border-radius: 0px !important;
  box-shadow: none;
  border-top: none;
}

.statistics-wrap .content-exam {
  background-color: #f2f2f2;
}

.statistics-wrap .content-exam_item {
  padding: 10px 0;
}

.statistics-wrap .content-exam ol {
  padding-left: 15px;
}

.statistics-wrap .visitors-wrap .panel-title {
  font-size: 14px !important;
  text-align: right;
  color: #cccccc;
}

.statistics-wrap .visitors-wrap .panel-title a {
  text-decoration: none;
  position: relative;
  padding-right: 15px;
}

.statistics-wrap .visitors-wrap .panel-title a i {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.3s linear;
}

.statistics-wrap .visitors-wrap .panel-title a.collapsed {
  position: relative;
}

.statistics-wrap .visitors-wrap .panel-title a.collapsed i {
  transform: translateY(-50%) rotate(0deg);
}

.statistics-wrap .visitors-wrap .panel-default {
  border-radius: 0px !important;
  box-shadow: none;
  border: none;
}

.statistics-wrap .visitors-wrap .panel-default > .panel-heading {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
}

.statistics-wrap .visitors-wrap .panel-body {
  border-top: none !important;
}

.request-notifications h2 {
  position: relative;
  display: inline-block;
}

.request-notifications h2 span.badge {
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  line-height: 21px;
  border-radius: 50%;
  background-color: #1422aa;
  font-size: 12px;
  text-align: center;
}

.request-notifications .panel-group .panel-default {
  border-top: 1px solid #ddd;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.request-notifications .panel-group .panel-default .panel-heading {
  background-color: transparent;
  padding: 0;
}

.request-notifications .panel-group .panel-default .panel-heading .panel-title {
  font-family: Arial, sans-serif;
  font-weight: 700;
  padding: 0;
}

.request-notifications .panel-group .panel-default .panel-heading .panel-title a {
  padding: 1em;
  margin: 0;
  color: #1422aa;
}

.request-notifications .panel-group .panel-default .panel-heading .panel-title a:hover, .request-notifications .panel-group .panel-default .panel-heading .panel-title a:active {
  background-color: #f4fdfd;
}

.request-notifications .panel-group .panel-default .panel-heading .panel-title a.collapsed {
  color: #999;
}

.request-notifications .panel-group .panel-default .panel-body {
  border-top: none;
}

.request-notifications form {
  position: relative;
  overflow: hidden;
}

.request-notifications form .help-block {
  font-size: 16px;
  vertical-align: middle;
  margin: 0;
  display: flex;
  align-items: center;
}

.request-notifications form .help-block i {
  margin-right: 5px;
}

.request-notifications .education-wrap .form-check label {
  margin-right: 10px;
  font-size: 16px;
}

.request-notifications .education-wrap .input-group-addon {
  background-color: transparent;
  border: none;
}

.summary-wrap .item-summary {
  height: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 50px;
  padding: 0px 10px 50px 10px;
}

.summary-wrap .item-summary h3 {
  margin: 0 !important;
  font-size: 20px;
  padding-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #1422aa;
}

.summary-wrap .item-summary h3 i {
  margin-right: 10px;
}

.summary-wrap .item-summary p {
  color: #333;
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.summary-wrap .item-summary ol {
  margin: 0;
  padding-left: 15px;
}

.summary-wrap .item-summary .block-summa {
  font-size: 16px;
}

.summary-wrap .item-summary .block-summa table tr td {
  padding-right: 10px;
}

.summary-wrap .item-summary a.btn-link {
  font-size: 14px;
  position: absolute;
  bottom: 15px;
  left: 10px;
  color: #999 !important;
}

.summary-wrap .item-summary a.btn-link i {
  margin-left: 8px;
}

.summary-wrap .item-summary .envelope-icon {
  display: flex;
  align-items: center;
}

.summary-wrap .item-summary .envelope-icon a {
  margin-right: 15px;
}

.summary-wrap .item-summary .envelope-icon a i {
  font-size: 32px;
  color: #1422aa;
  position: relative;
}

.summary-wrap .item-summary .envelope-icon span {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: #fff;
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  border-radius: 50%;
  background-color: green;
}

.summary-wrap .item-summary .envelope-icon span.bg-red {
  background-color: red !important;
  width: 16px;
  height: 16px;
}

.summary-wrap .item-summary .situations-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary-wrap .item-summary .situations-wrap .item-situations {
  text-decoration: none;
  color: #000000;
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.summary-wrap .item-summary .situations-wrap .item-situations img {
  height: 32px;
  width: 32px;
}

.summary-wrap .item-summary .situations-wrap .item-situations p {
  font-size: inherit;
  margin: 0;
  padding: 0 0 0 15px;
}

.summary-wrap .item-summary .carousel {
  position: relative;
}

.summary-wrap .item-summary .carousel .item-poll {
  padding: 0;
}

.summary-wrap .item-summary .carousel .item-poll label {
  display: block;
}

.summary-wrap .item-summary .carousel .controls-wrap {
  left: 120px;
  position: absolute;
  top: -2px;
}

.summary-wrap .item-summary .carousel .controls-wrap a {
  font-size: 18px;
  margin: 0 10px;
  text-decoration: none;
}

.listeners-wrap .listener-item .documents-item {
  padding: 15px 0;
}

.listeners-wrap .listener-item .documents-item .user,
.listeners-wrap .listener-item .documents-item .program {
  transition: all 0.25s ease;
  color: #000;
  text-decoration: none;
}

.listeners-wrap .listener-item .documents-item .user i,
.listeners-wrap .listener-item .documents-item .program i {
  margin-left: 10px;
}

.listeners-wrap .listener-item .documents-item .user:hover,
.listeners-wrap .listener-item .documents-item .program:hover {
  color: #1422aa;
}

.listeners-wrap ul .list-inline {
  padding-left: 20px;
}

.listeners-wrap ul li {
  margin-right: 30px;
  font-size: 14px;
  color: #666;
}

.listeners-wrap ul li i {
  color: #1422aa;
  margin-right: 5px;
}

.listeners-wrap .listener-item-programs .edit-program-wrap,
.listeners-wrap .listener-item-programs .add-program-wrap {
  padding-left: 20px;
}

.listeners-wrap .listener-item-programs .edit-program-wrap h5,
.listeners-wrap .listener-item-programs .add-program-wrap h5 {
  margin: 0 0 10px 0;
}

.listeners-wrap .listener-item-programs .program-text {
  position: relative;
  padding-left: 20px;
  padding-top: 5px;
}

.listeners-wrap .listener-item-programs .program-text a {
  color: #666;
  text-decoration: none;
}

.listeners-wrap .listener-item-programs .program-text i {
  position: absolute;
  top: 10px;
  left: 0;
}

.listeners-wrap .listener-item-programs .status {
  font-style: italic;
}

.listeners-wrap .listener-item-programs .status i {
  color: #3fa435;
  margin-right: 5px;
}

.contract-notification_head {
  border-top: 1px solid #dddddd;
  padding: 0.8em 1em;
}

.contract-notification_head .panel-title {
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: #777;
}

.contract-notification_content {
  position: relative;
}

.contract-notification_content::after {
  content: '\f00e';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 4em;
  color: #777777;
  opacity: 0;
  transition: all 0.5s ease;
}

.contract-notification_content:hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.contract-notification_content .contract-edit_head .panel-title {
  font-family: Arial, sans-serif;
  font-size: 0.9em;
  font-weight: 700;
  color: #999999;
  padding: 0.7em 1em;
}

.contract-notification_content .panel-body .contract-edit_content {
  background-color: #f2f2f2;
  padding: 1.5em 3em;
}

.history-wrap table tr td {
  padding: 5px 15px;
  position: relative;
}

.history-wrap .new {
  position: relative;
}

.history-wrap .new::before {
  position: absolute;
  content: "\f06a";
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  color: #1422aa;
  left: -5px;
}

.timetable-wrap .timetable-item {
  padding: 15px 0;
}

.timetable-wrap .timetable-item .timetable-programm {
  margin-top: 20px;
}

.documents-wrap .documents-item {
  padding: 15px 0;
}

.documents-wrap .list-unstyled {
  padding-left: 20px;
}

.documents-wrap .list-unstyled li {
  padding: 5px 0;
}

.tracking-wrap .tracking-item {
  padding: 15px 0;
}

.tracking-wrap .tracking-item .tracking-programm {
  margin-top: 20px;
}

.tracking-wrap .tracking-item .tracking-programm i {
  margin-right: 5px;
}

.tracking-wrap .tracking-item .tracking-programm .status {
  font-style: italic;
}

.seminar-wrap {
  z-index: 10;
}

.seminar-wrap .seminar-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 40px;
  width: 100%;
  margin: 0 auto;
}

.seminar-wrap .seminar-header p {
  font-size: 18px;
}

.seminar-wrap .seminar-header h2 {
  font-size: 42px;
  font-weight: 700;
}

.seminar-wrap .seminar-header ul {
  font-size: 16px;
}

.seminar-wrap .seminar-header ul li {
  display: inline-block;
  margin-right: 15px;
}

.seminar-wrap .seminar-header ul li i {
  margin-right: 5px;
}

.seminar-wrap .seminar-form-wrap {
  border: 4px solid #fecf73;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
}

.seminar-wrap .seminar-form-wrap h3 {
  font-size: 30px;
  margin: 0;
  padding-bottom: 10px;
  color: #fecf73;
}

.seminar-wrap .seminar-form-wrap p {
  font-size: 14px;
}

.seminar-wrap .seminar-form-wrap button.btn-warning {
  width: 100%;
}

.seminar-wrap .seminar-form-wrap .summa {
  color: #fff;
  display: block;
  margin-top: 10px;
  font-size: 30px;
  padding: 10px;
  border-bottom: 1px solid #fff;
}

.seminar-wrap .seminar-options {
  border-bottom: 1px solid #ddd;
  padding: 25px 0;
}

.seminar-wrap .seminar-options p i {
  margin-right: 5px;
}

.seminar-wrap .seminar-accordion-item a {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.seminar-wrap .seminar-accordion .content {
  padding-left: 15px;
}

.seminar-wrap .seminar-accordion .content table {
  width: 100%;
}

.seminar-wrap .seminar-accordion .content table tr td {
  padding: 10px;
}

.seminar-wrap .dl-horizontal {
  display: flex;
}

.seminar-wrap .dl-horizontal img {
  width: 70px;
  display: inline-block;
  margin-right: 15px;
}

.seminar-wrap .seminar-carousel .carousel-inner {
  width: 90%;
  margin: 20px auto;
}

.seminar-wrap .seminar-carousel .carousel-inner .item-wrap {
  display: flex;
}

.seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img img {
  border-radius: 50%;
  margin: 20px 30px;
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  box-shadow: 0 0 0 20px #f2f2f2;
}

.seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img p {
  color: #1422aa;
  font-size: 1em;
  padding: 10px;
  text-align: center;
}

.seminar-wrap .seminar-carousel .carousel-inner .item-wrap-text {
  padding: 15px;
}

.seminar-wrap .seminar-carousel .carousel-control {
  background: none;
  color: #ddd;
  width: 5%;
}

.progress.study-progress {
  display: inline-block;
  margin-bottom: 0;
  width: 100%;
}

.table-noborder > tbody > tr > td,
.table-noborder > tbody > tr > th,
.table-noborder > tfoot > tr > td,
.table-noborder > tfoot > tr > th,
.table-noborder > thead > tr > td,
.table-noborder > thead > tr > th {
  border: none;
}

.panel-question {
  border-radius: 16px;
  border-color: #999;
  margin-bottom: 2em;
  border-collapse: collapse;
  overflow: hidden;
}

.panel-question .file-wrap label {
  background-color: #fff;
}

.panel-question textarea {
  padding: 15px 20px;
  max-width: 100%;
  min-width: 100%;
  min-height: 200px;
  max-height: 400px;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid #ddd;
}

.panel-question input[type="text"] {
  background-color: #fff;
}

.menu-block {
  display: inline-flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: 8px;
}

.menu-block .group-menu-block {
  display: flex;
  justify-content: space-between;
}

.menu-block .group-menu-block .menu-block-users {
  display: flex;
  align-items: center;
}

.menu-block .group-menu-block .menu-block-geo a {
  display: flex;
  align-items: center;
  color: #fff;
}

.menu-block .group-menu-block .menu-block-geo a:hover {
  text-decoration: none;
  color: #fecf73;
}

.menu-block i {
  font-size: 30px;
  margin-right: 10px;
  margin-left: 30px;
}

.menu-block span {
  font-size: 20px;
}

.menu-block span small {
  font-size: 13px;
}

.seminar-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
}

.seminar-header::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.seminar-header h3 {
  color: #fff;
  font-weight: 900;
  font-size: 32px;
  font-family: RobotoBlack, sans-serif;
}

.seminar-header .text {
  line-height: 22px;
}

.seminar-header .text span {
  text-transform: uppercase;
  color: #a3d3e5;
  font-weight: 700;
  font-size: 14px;
}

.seminar-header .text p {
  color: #fecf73;
  font-size: 26px;
}

.seminar-wrap {
  position: relative;
}

.seminar-wrap h1 {
  font-size: 2.8em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: RobotoBold, sans-serif;
  color: #fff;
}

.seminar-wrap h1 span {
  font-weight: 900;
  font-family: RobotoBlack, sans-serif;
  font-size: 1.3em;
  color: #fecf73;
}

.seminar-wrap a.btn-warning {
  font-weight: 700;
  color: #1422aa;
}

.seminar-wrap a.btn-warning:hover {
  color: #fff;
}

.seminar-wrap .map-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.seminar-wrap .map-wrap img {
  width: 100%;
  opacity: 0.3;
}

.seminar-wrap .seminar-bottom-text {
  color: #fff;
}

.seminar-wrap .seminar-bottom-text li {
  font-size: 1.3em;
}

.seminar-wrap .seminar-bottom-text a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.seminar-wrap .seminar-bottom-text a:hover {
  color: #fecf73;
}

.seminar-wrap .seminar-bottom-text p {
  margin: 0;
  font-size: 0.6em;
  line-height: 1.5em;
}

@media only screen and (max-width: 991.98px) {
  .catalog-seminar-wrap .title {
    margin-bottom: 30px;
  }
}

.catalog-seminar-wrap .min-img {
  display: block;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
}

.catalog-seminar-wrap .min-img img {
  width: 100%;
}

.reviews-seminar-wrap .reviews-item .city {
  color: #999;
}

.reviews-seminar-wrap .reviews-item .city i {
  margin-right: 5px;
}

.reviews-seminar-wrap .reviews-item .stars {
  color: #999;
}

.reviews-seminar-wrap .reviews-item .stars i {
  color: transparent;
  padding: 0;
  margin-right: -4px;
}

.reviews-seminar-wrap .reviews-item .stars i.active {
  color: #fecf73;
}

.reviews-seminar-wrap .reviews-item .stars a.gray {
  margin-left: 25px;
}

.reviews-seminar-wrap .reviews-item div.colaps {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  transition: all 0.3s ease;
}

.reviews-seminar-wrap .reviews-item div.colaps.in {
  height: auto;
  white-space: normal;
}

.seminar-block-text {
  font-size: 1em;
}

.seminar-block-text h3 {
  font-family: RobotoBlack, sans-serif;
  font-weight: 700;
  font-size: 34px;
  padding-bottom: 15px;
}

.seminar-block-text p.orange {
  line-height: 36px;
  padding-bottom: 15px;
}

.seminar-block-text p {
  font-size: 1.5em;
}

.seminar-block-text p span {
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 700;
}

.semitar-interactive-maps .map-region {
  fill: #1422aa;
  fill-opacity: 0.2;
  transition: fill-opacity .4s ease-out;
}

.semitar-interactive-maps .map-region:hover {
  fill: #fecf73;
  fill-opacity: 1;
  cursor: pointer;
}

.semitar-interactive-maps .static-text-map {
  margin-top: 100px;
  font-size: 1em;
  line-height: 1.65em;
}

.semitar-interactive-maps .static-text-map p.oblast {
  font-size: 1em;
  position: relative;
  padding-left: 1.2em;
}

.semitar-interactive-maps .static-text-map p.oblast i {
  position: absolute;
  top: 3px;
  left: 0;
  margin-right: 5px;
  color: #fecf73;
  font-size: 1em;
}

.semitar-interactive-maps .static-text-map p.count {
  font-size: 1em;
}

.semitar-interactive-maps .static-text-map p.count span {
  font-weight: 900;
  color: #1422aa;
  font-size: 1.4em;
}

.study-cases h2 {
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 1em;
}

.study-cases .cases-list {
  margin-right: -15px;
  margin-left: -15px;
  column-count: 2;
  column-gap: 4em;
  padding: 2em;
}

.study-cases .cases-list > .column-block {
  margin-bottom: 3em;
}

.study-cases .cases-list h3 {
  margin-top: 0;
  color: #1422aa;
  font-size: 20px;
  font-weight: 700;
}

.study-cases .cases-list h4 {
  font-size: 16px;
  font-weight: 700;
}

.study-cases .cases-list img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.study-cases .cases-list .list-group .list-group-item {
  border-radius: 0;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.study-cases .cases-list .list-group .list-group-item h4 {
  font-family: Arial, sans-serif;
}

.study-cases .cases-list .list-group .list-group-item p {
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 0.9em;
}

.study-cases .cases-list .list-group .list-group-item:hover, .study-cases .cases-list .list-group .list-group-item:focus, .study-cases .cases-list .list-group .list-group-item:active {
  background-color: transparent;
}

.study-cases .cases-list .list-group .list-group-item:hover h4, .study-cases .cases-list .list-group .list-group-item:focus h4, .study-cases .cases-list .list-group .list-group-item:active h4 {
  text-decoration: underline;
  color: #1422aa;
}

.h14 {
  font-size: 14px;
  font-family: "Arial Regular", sans-serif;
  color: #000000;
}

#modal-stock .modal-content {
  background: #8ec6ff;
  background: -moz-radial-gradient(center at 30% 50%, ellipse farthest-corner, #8ec6ff 0%, #60abf8 42%, #4096ee 100%);
  background: -webkit-radial-gradient(center at 30% 50%, ellipse farthest-corner, #8ec6ff 0%, #60abf8 42%, #4096ee 100%);
  background: radial-gradient(ellipse at 30% 50%, #8ec6ff 0%, #60abf8 42%, #4096ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8ec6ff', endColorstr='#4096ee', GradientType=1);
}

#modal-stock .modal-body {
  padding: 0;
}

#modal-stock .modal-body .close {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 2;
  font-size: 30px;
}

#modal-stock .modal-body .item {
  position: relative;
}

#modal-stock .modal-body .item::before {
  content: '';
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(142, 198, 255, 0.9);
}

#modal-stock .modal-body .item .red-ribbon-line {
  width: 25px;
  background-color: #FF141F;
  height: 100%;
  min-height: 100px;
  position: absolute;
  top: 0;
  left: 105px;
  z-index: -1;
}

#modal-stock .modal-body .item .red-ribbon-img {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: -1;
}

#modal-stock .modal-body .item .star-ribbon-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#modal-stock .modal-body .item .modal-custom-form input {
  border: none !important;
  padding: 10px 15px !important;
  background-color: #f2f2f2 !important;
  height: 40px;
  outline: none;
}

#modal-stock .modal-body .item .modal-custom-form textarea {
  background-color: #f2f2f2 !important;
  outline: none;
  border: none !important;
}

#modal-stock .modal-body .item .modal-custom-form .datepicker .input-date {
  width: 175px;
  height: 40px;
  background: #f2f2f2 !important;
  vertical-align: middle;
  line-height: 40px;
  position: relative;
}

#modal-stock .modal-body .item .modal-custom-form .datepicker .input-time {
  line-height: 40px;
  height: 40px;
  vertical-align: middle;
}

#modal-stock .modal-body .item .modal-custom-form .datepicker .input-group-addon {
  position: absolute;
  display: block;
  top: 0px !important;
  right: 0px !important;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  text-align: center;
  border: none;
  z-index: 5;
}

#modal-stock .modal-body .item .modal-custom-form .datepicker .input-group-addon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#modal-stock form.modal-form-stock table tr {
  border-bottom: 1px solid #dddddd;
}

#modal-stock form.modal-form-stock table tr td {
  padding: 0.7em 0.5em;
  position: relative;
  font-size: 1em;
}

#modal-stock form.modal-form-stock table tr td.small {
  font-size: 12px;
  line-height: 11px;
}

#modal-stock form.modal-form-stock .form-check {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

#modal-stock form.modal-form-stock .form-check label {
  position: relative;
  margin: 0;
  display: block !important;
  width: 100%;
  height: 100%;
}

#modal-stock form.modal-form-stock .form-check label input[type="radio"] {
  position: relative;
}

#modal-stock form.modal-form-stock .form-check label input[type="radio"] + .label-text:before {
  color: #fff;
  display: block;
}

#modal-stock form.modal-form-stock .form-check label .label-text:before {
  position: absolute;
  top: 20%;
}

#modal-stock form.modal-form-stock .form-check label .label-text {
  color: #000000;
}

#modal-stock form.form-horizontal textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 300px;
  min-height: 50px;
  padding-top: 15px;
}

#modal-stock form.form-horizontal .glyphicon {
  top: 0;
}

#modal-stock form.form-horizontal .input-group-addon {
  background-color: #f2f2f2;
}

#modal-consultation .modal-content {
  position: relative;
}

#modal-consultation .modal-content button.close {
  position: absolute;
  z-index: 2;
  font-size: 30px;
  top: 10px;
  right: 15px;
}

#modal-consultation .modal-content .modal-body {
  padding: 20px 30px;
}

#modal-consultation .modal-content .modal-body .form-group select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

#modal-sentence .modal-content {
  position: relative;
}

#modal-sentence .modal-content button.close {
  position: absolute;
  z-index: 2;
  font-size: 30px;
  top: 10px;
  right: 15px;
}

#modal-sentence .modal-content .modal-body {
  padding: 20px 30px;
}

.seminar-head_banner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.seminar-head_banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.seminar-head_banner .img-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  filter: blur(5px);
  transform: scale(1.02);
  z-index: -1;
  display: block;
}

.seminar-head_banner-desc {
  z-index: 2;
  padding: 30px 0;
  color: #fff;
  position: relative;
}

.seminar-head_banner-desc .star {
  font-size: 1.4em;
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  height: 2em;
  margin: 2em;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  text-shadow: 0 0 4px #666666;
}

.seminar-head_banner-desc .star:before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "\F005";
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  color: #f9b233;
  width: 2em;
  height: 2em;
  z-index: -1;
  font-size: 2.5em;
  display: inline-block;
  margin-left: -1em;
  margin-top: -1em;
  text-shadow: 0 0 2px #fff;
}

.seminar-head_banner-desc .teacher {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 18px;
}

.seminar-head_banner-desc .teacher img {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.seminar-head_banner-desc .teacher .stars i {
  color: #f9b233;
  text-shadow: 0 0 1px #505050;
}

.seminar-head_banner-desc .list {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}

.seminar-head_banner-desc .list i {
  margin-right: 10px;
}

.seminar-head_banner-title {
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: 500;
  padding: 1.2em 0;
  margin: 0;
  font-size: 2.2em;
  line-height: 1.3em;
}

.nav-study {
  border-bottom: 1px solid #1422aa;
}

.nav-study .buttom-menu ul li a {
  transition: all 0.3s ease;
}

.nav-study .buttom-menu ul li a:hover {
  background-color: #a3d3e5 !important;
  color: #fff;
}

.seminar-modules {
  padding-top: 40px;
  padding-bottom: 40px;
}

.seminar-modules .module-item {
  margin-bottom: 4em;
}

.seminar-modules i {
  margin-right: 10px;
}

.tv {
  background: #b5bdc8;
  background: -moz-radial-gradient(center, ellipse farthest-corner, #b5bdc8 0%, #828c95 36%, #28343b 100%);
  background: -webkit-radial-gradient(center, ellipse farthest-corner, #b5bdc8 0%, #828c95 36%, #28343b 100%);
  background: radial-gradient(ellipse at center, #b5bdc8 0%, #828c95 36%, #28343b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b5bdc8', endColorstr='#28343b', GradientType=1);
}

.bg-logo {
  background-image: url(/img/logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

a.iframe-video-play-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

a.iframe-video-play-btn:hover {
  transform: scale(1.2);
  color: #fecf73;
}

#scrollspy {
  font-size: 90%;
}

#scrollspy li.active > a {
  font-weight: bold;
}

textarea.custom-textarea {
  padding: 15px 20px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  max-height: 400px;
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid #ddd;
}

.seminar-feedback {
  padding-top: 40px;
  padding-bottom: 40px;
}

.seminar-feedback .img-wrap {
  border-radius: 50%;
  width: 240px;
  min-width: 240px;
  height: 240px;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 50%;
  border: 30px solid #f6f6f6;
}

.seminar-feedback .img-wrap img {
  width: 100%;
  height: auto;
}

.seminar-feedback .signature-seminar {
  width: 150px;
  height: auto;
}

.star-rating {
  font-size: 0;
}

.star-rating_wrap {
  display: inline-block;
  font-size: 3rem;
  font-size-position: relative;
}

.star-rating_ico {
  float: right;
  cursor: pointer;
  color: #FFB300;
  color-position: relative;
}

.star-rating_ico:before {
  content: "\f005";
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 400;
}

.star-rating_ico:last-child {
  padding-left: 0;
}

.star-rating_input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.star-rating_ico:hover:before,
.star-rating_ico:hover ~ .star-rating_ico:before,
.star-rating_input:checked ~ .star-rating_ico:before {
  font-weight: 900;
}

.s-saying .media {
  min-height: 120px;
  height: auto;
  margin-bottom: 20px;
}

.s-saying .media img.media-object {
  width: 100px;
  height: auto;
}

.recommend-colleague .letter-edit {
  border: 2px solid #1422aa;
  border-radius: 1em;
  padding: 15px;
  background-color: rgba(254, 207, 115, 0.3);
  color: #555;
  position: relative;
}

.recommend-colleague .letter-edit-bg {
  position: absolute;
  width: 180px;
  height: auto;
  opacity: 0.2;
  bottom: 10%;
  right: 12%;
}

.recommend-colleague .letter-edit-marka {
  text-align: right;
}

.recommend-colleague .letter-edit-marka img {
  width: 70px;
  margin: 5px;
  border: 1px solid #999;
}

.bg1-seminar-about {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
  background-color: #1422aa;
  position: relative;
}

.bg1-seminar-about .video-bg-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(90deg, #1422aa 50%, transparent 50.1%);
}

.bg1-seminar-about .video-bg-wrap video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
  z-index: 1;
}

@supports not (mix-blend-mode: multiply) {
  .bg1-seminar-about .video-bg-wrap::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1422aa;
    opacity: 0.7;
    z-index: 2;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bg1-seminar-about .video-bg-wrap::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1422aa;
    opacity: 0.7;
    z-index: 2;
  }
}

.bg2-seminar-about {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.bg2-seminar-about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.bg2-seminar-about .border-box {
  border: 2px solid #fff;
  border-radius: 20px;
  text-align: center;
  padding: 15px;
}

.bg2-seminar-about .border-box h1 {
  font-family: RobotoBlack, sans-serif;
  text-align: center;
  font-size: 2.7em;
  color: #fff;
}

.bg2-seminar-about .border-box .btn-transp {
  font-size: 1em;
}

.s-workshop-participant .box-workshop-participant {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 0;
  color: #666666;
}

.s-workshop-participant .box-workshop-participant h4 {
  text-transform: uppercase;
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 1.2em;
}

.s-workshop-participant .box-workshop-participant .blue-circle {
  display: block;
  margin: 0 auto;
  width: 140px;
  height: 140px;
  background-color: #1422aa;
  color: #fff;
  font-size: 4em;
  font-weight: 900;
  border-radius: 50%;
  text-align: center;
  line-height: 140px;
  margin-bottom: 0.5em;
}

.tree {
  min-height: 20px;
  padding-top: 3px;
  padding-right: 8px;
  padding-left: 0;
  padding-bottom: 3px;
  margin-bottom: 20px;
}

.tree li {
  list-style-type: none;
  margin: 0;
  padding: 10px 5px 0 5px;
  position: relative;
}

.tree li::before, .tree li::after {
  content: '';
  left: -20px;
  position: absolute;
  right: auto;
}

.tree li::before {
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}

.tree li::after {
  border-top: 1px solid #999;
  height: 20px;
  top: 25px;
  width: 25px;
}

.tree li div.span {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #999;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
}

.tree li.parent_li > div.span {
  cursor: pointer;
}

.tree li:last-child::before {
  height: 26px;
}

.tree li.parent_li > div.span:hover {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000;
}

.tree li.parent_li > div.span:hover + ul li div.span {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000;
}

.tree li h3 {
  display: inline-block;
  vertical-align: middle;
}

.tree li a h3 {
  margin: 0;
}

.tree li a:hover h3 {
  text-decoration: none;
}

.tree > ul {
  padding-left: 0;
}

.tree > ul > li {
  padding-left: 0;
}

.tree > ul {
  padding-left: -40px;
}

.tree > ul > li::before, .tree > ul > li::after {
  border: 0;
}

.flipper-card.flipped {
  transform: rotateY(180deg);
}

.thumbnail:hover {
  text-decoration: none;
}

.thumbnail .caption {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.thumbnail .caption .top-block {
  display: flex;
  align-items: center;
}

.thumbnail .caption .top-block i {
  font-size: 2.5em;
  margin-right: 0.5em;
}

.thumbnail .caption .head {
  font-size: 1.3em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #000000;
}

.thumbnail .caption .bottom-block {
  display: flex;
  justify-content: space-between;
  color: #1422aa;
}

.timer {
  text-align: center;
}

.timer .img-circle {
  background-color: #fecf73;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  white-space: nowrap;
}

.breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
}

nav.solidarity a.active {
  font-weight: bold;
}

.main--margin-top {
  margin-top: 40px;
}

.rs-btn {
  margin-top: 11px;
  display: block;
  color: #ccc;
  opacity: 1;
  cursor: pointer;
}

.page__title {
  align-self: baseline;
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: #1422aa;
  margin-top: 50px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .page__title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.page__title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
}

.breadcrumbs {
  display: flex;
  color: #ccc;
  font-family: Arial, sans-serif;
  font-weight: 400;
}

.breadcrumbs__link {
  display: inline-block;
  font-size: 1em;
  color: #ccc;
  text-decoration: none;
}

.breadcrumbs__link:hover {
  color: #ccc;
}

.breadcrumbs__link:visited {
  color: #ccc;
}

.breadcrumbs__divider {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1em;
  color: #ccc;
  text-decoration: none;
}

.stat {
  border: none;
  background: none;
}

.stat__item {
  margin-right: 1em;
  font-size: 0.75em;
  color: #ccc;
  display: inline-block;
}

.stat--article .stat__item {
  display: inline-block;
  margin-right: 2em;
  font-size: 1em;
}

.auth-reg {
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1em;
}

.auth-reg__item {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  outline: 0;
  cursor: pointer;
  text-decoration: underline;
}

.auth-reg__item:hover {
  color: #1422aa;
}

.auth-reg__divider {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1em;
  color: #ccc;
  text-decoration: none;
}

.display-filter {
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 1em;
  text-align: right;
  color: #ccc;
}

@media only screen and (max-width: 991.98px) {
  .display-filter {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .display-filter {
    text-align: left;
    margin-bottom: 15px;
  }
}

.display-filter__item {
  display: inline-block;
  margin: 0 0 0 18px;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  outline: 0;
  cursor: pointer;
}

.display-filter__item:hover {
  text-decoration: underline;
}

.display-filter__item--active {
  color: #a3d3e5;
  cursor: default;
}

.display-filter__item--active:hover {
  text-decoration: none;
}

.quotes {
  position: relative;
  margin: 55px 0 35px 0;
  padding: 120px 75px;
  border: 2px solid #1422aa;
}

@media (max-width: 768px) {
  .quotes {
    padding: 100px 30px 50px 30px;
  }
}

.quotes:before {
  content: '“';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  text-align: center;
  font-size: 100px;
  color: #1422aa;
}

.quotes__text {
  font-family: RobotoLight, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 1.875em;
  line-height: 1.2em;
  color: #333;
}

@media (max-width: 700px) {
  .quotes__text {
    font-size: 1.125em;
  }
}

.quotes__text--comment {
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 1.125em;
}

.social {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.social li {
  margin-left: 10px;
  font-size: 0.875em;
}

/*==========  Desktop First  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1199.98px) {
  header .s-header {
    padding: 40px 0;
  }
  header .s-header .logo img {
    width: 60px;
    height: auto;
    float: left;
    margin-right: 10px;
  }
  header .s-header .logo-title {
    font-size: 15px;
    font-family: Roboto, sans-serif;
    display: inline-block;
  }
  header .s-header .logo-title span {
    font-weight: 400;
    font-size: 10px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.60em;
  }
  .text-columns-5 {
    column-count: 5;
  }
  .text-columns-2 {
    column-count: 2;
  }
  .s-header .logo img {
    width: 70px;
    height: auto;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1 item-2 item-3 item-4" "item-5 item-2 item-6 item-7" "item-8 item-9 item-11 item-7" "item-8 item-10 item-11 item-12";
  }
  .menu-block {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .menu-block .menu-block-link a.btn-white-min {
    font-size: 12px;
  }
  .menu-block .group-menu-block {
    display: flex;
    justify-content: space-between;
  }
  .menu-block .group-menu-block .menu-block-users {
    display: flex;
    align-items: center;
  }
  .menu-block .group-menu-block .menu-block-geo a {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .menu-block .group-menu-block .menu-block-geo a:hover {
    text-decoration: none;
    color: #fecf73;
  }
  .menu-block i {
    font-size: 20px;
  }
  .menu-block span {
    font-size: 16px;
  }
  .menu-block span small {
    font-size: 13px;
  }
  .s-loyalty .item-loyalty span {
    font-size: 1.5em;
  }
  .study-wrap .panel-body a.btn-block {
    font-size: 0.8em;
  }
  .seminar-about-head h1 {
    font-size: 3.4em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  .seminar-about-medal {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 11em;
  }
}

@media only screen and (max-width: 991.98px) {
  .text-columns-5 {
    column-count: 4;
  }
  .text-columns-2 {
    column-count: 2;
  }
  .s-header {
    background-color: #ffffff;
  }
  .s-header .logo img {
    width: 70px;
    height: auto;
  }
  .s-header .header-phone .header-phone-number {
    margin: 0;
  }
  .s-header .header-phone .number {
    font-size: 0;
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid #1422aa;
    position: relative;
    border-radius: 50%;
    transition: all 0.3s;
    text-decoration: none;
  }
  .s-header .header-phone .number::before {
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f095";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #1422aa;
  }
  .s-header .header-phone .number:hover {
    background-color: #1422aa;
  }
  .s-header .header-phone .number:hover::before {
    color: #ffffff;
  }
  .s-header .btn-wrap {
    display: flex;
    justify-content: space-around;
  }
  .s-header .btn-wrap .header-btn {
    position: relative;
  }
  .s-header .btn-wrap .header-btn .btn-login {
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid #1422aa;
    position: relative;
    text-decoration: none;
  }
  .s-header .btn-wrap .header-btn .btn-login::before {
    content: '\f084';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    color: #1422aa;
  }
  .s-header .btn-wrap .header-btn .btn-login:hover::before {
    color: #ffffff;
  }
  .s-header .btn-wrap .header-btn .btn-user {
    padding: 0 !important;
    width: 50px;
    height: 50px;
  }
  .s-header .btn-wrap .header-btn button {
    z-index: 25 !important;
  }
  .s-header .btn-wrap .header-btn .btn-group {
    position: relative;
    display: block;
  }
  .s-header .btn-wrap .header-btn .btn-group span.caret {
    display: none;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu {
    position: absolute;
    left: -220%;
    top: 55px;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li:first-child {
    padding-top: 5px;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li {
    padding: 2px 0;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li i {
    margin-left: 7px;
    color: #1422aa;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li.divider {
    padding: 0;
    margin: 5px 0;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu a {
    padding: 10px 20px;
  }
  .s-header .btn-wrap .header-btn .btn-group.open button {
    background-color: #f9b233 !important;
  }
  .s-header .btn-wrap .header-cart {
    position: relative !important;
  }
  .s-header .btn-wrap .header-cart a {
    font-size: 0;
  }
  .s-header .btn-wrap .header-cart .min-app {
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid #1422aa;
    position: relative;
    border-radius: 50%;
    text-decoration: none;
  }
  .s-header .btn-wrap .header-cart .min-app::before {
    content: '\f15c';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #1422aa;
  }
  .s-header .btn-wrap .header-cart .min-app:hover {
    background-color: #1422aa;
  }
  .s-header .btn-wrap .header-cart .min-app:hover::before {
    color: #ffffff;
  }
  .s-header .btn-wrap .header-cart .min-app span {
    font-size: 12px;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: -5px;
    right: -7px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    background-color: #1422aa;
    color: #ffffff;
    border-radius: 50%;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1 item-2 item-3" "item-5 item-2 item-6" "item-8 item-9 item-11" "item-8 item-7 item-12" "item-4 item-7 item-10";
  }
  .s-facts .facts-grid .item {
    position: relative;
  }
  .s-facts .facts-grid .item > div {
    opacity: 1;
  }
  .request-wrap .left-aside-bar h4 {
    position: relative;
    padding: 10px;
    text-align: center;
    text-align-background-color: transparent;
  }
  .request-wrap .left-aside-bar h4:first-child {
    padding: 10px 0 10px 0px;
    margin: 0;
  }
  .request-wrap .left-aside-bar h4 i {
    font-size: 30px;
    text-align: center;
    display: inline-block;
  }
  .request-wrap .left-aside-bar a.list-group-item {
    border: none;
    background-color: transparent;
    position: relative;
    transition: all 0.2s linear;
    color: #666666;
    padding: 20px 0 15px 0;
    text-align: center;
  }
  .request-wrap .left-aside-bar a.list-group-item:hover {
    background-color: transparent;
    color: #666666;
  }
  .request-wrap .left-aside-bar a.list-group-item span.badge {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    line-height: 18px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 5px;
    background-color: #1422aa;
    top: 15px;
    font-size: 10px;
  }
  .request-wrap .left-aside-bar a.list-group-item i {
    font-size: 22px;
  }
  .request-wrap .left-aside-bar a.list-group-item.active {
    color: #000000;
    background-color: transparent;
    position: relative;
  }
  .request-wrap .left-aside-bar a.list-group-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #a3d3e5;
  }
  .request-wrap .left-aside-bar a.list-group-item.active:hover {
    color: #666666;
  }
  footer .footer-bottom .footer-links {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  footer .footer-bottom .footer-links ul li {
    display: block;
    margin-right: 0px;
  }
  footer .footer-bottom .copy {
    text-align: left;
  }
  .razvitum-steps ol li {
    text-align: center;
  }
  .razvitum-steps ol li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 95%;
    height: 1px;
    display: block;
    background-color: #000;
  }
  .razvitum-steps ol li span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-right: 0px;
  }
  .razvitum-steps ol li span i.counter {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .seminar-header h3 {
    font-size: 32px;
  }
  .seminar-header .text span {
    font-size: 14px;
  }
  .seminar-header .text p {
    font-size: 20px;
  }
  .seminar-block-text .btn-white {
    font-size: 0.8em;
  }
  .seminar-block-text p {
    font-size: 1.2em;
  }
  .seminar-block-text p span {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 700;
  }
  .study-wrap .panel-body a.btn-block {
    font-size: 1em;
  }
  .seminar-about-head h1 {
    font-size: 3.4em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  .seminar-about-medal {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: left;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 3.7em;
    position: relative;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    position: relative;
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .mt-sm-4 {
    margin-top: 4em !important;
  }
  .mt-sm-3 {
    margin-top: 3em !important;
  }
  header .s-header {
    padding-top: 18px !important;
    background: #fff;
  }
  header .s-header .navbar-toggle .icon-bar {
    width: 100% !important;
  }
  header .s-header .logo-wrap a.logo {
    padding: 0;
  }
  header .s-header .logo-wrap .logo {
    margin: 0 !important;
    display: block;
    padding-top: 10px;
    position: fixed;
    top: 5px;
    left: 10px;
    z-index: 20;
  }
  header .s-header .logo-wrap .logo img {
    width: 50px !important;
    height: auto;
    float: none;
    margin-right: 0px;
  }
  header .s-header .logo-wrap .logo-title {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    display: block;
    padding-top: 5px;
    display: none;
  }
  header .s-header .logo-wrap .logo-title span {
    font-weight: 400;
    font-size: 10px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.55em;
    text-align: center;
  }
  header .s-header .header-phone {
    position: fixed;
    top: 5px;
    right: 135px;
    z-index: 20;
  }
  header .s-header .btn-wrap .header-cart {
    position: fixed !important;
    display: block;
    top: 5px !important;
    right: 195px !important;
    z-index: 20;
    left: unset !important;
    transform: translateX(0) !important;
    height: 50px;
  }
  header .s-header .btn-wrap .header-btn {
    position: fixed;
    top: 5px;
    right: 75px;
    z-index: 20;
  }
  .text-columns-5 {
    column-count: 2;
  }
  .text-columns-2 {
    column-count: 1;
  }
  .s-story {
    padding-bottom: 50px;
  }
  .s-story .text {
    padding-bottom: 30px;
    font-size: 18px;
  }
  .s-story .bg-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .s-story .bg-wrap .col-6 {
    width: 50%;
  }
  .s-story .bg-wrap .item-story {
    width: 100%;
  }
  .dropdown-menu {
    position: relative;
  }
  .navbar-collapse {
    overflow-y: scroll;
    height: 100vh;
  }
  .menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 19;
    background: #fff !important;
  }
  .menu-wrap .navbar.navbar-default {
    background-color: #ffffff;
    border-bottom: 1px solid #1422aa;
  }
  .menu-wrap .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff;
  }
  .menu-wrap .dropdown-menu > li > a:focus, .menu-wrap .dropdown-menu > li > a:hover {
    background-color: transparent !important;
    background-image: none !important;
  }
  .menu-wrap .buttom-menu {
    background-color: #1422aa;
  }
  .menu-wrap .buttom-menu .container {
    padding: 0;
  }
  .menu-wrap .buttom-menu ul {
    text-align: left !important;
  }
  .menu-wrap .buttom-menu ul li {
    width: 100%;
  }
  .menu-wrap .buttom-menu ul li a {
    color: #ffffff;
    text-transform: uppercase;
    text-align: left;
  }
  .menu-wrap .navbar {
    min-height: 60px;
  }
  .menu-wrap .navbar-toggle {
    position: fixed;
    z-index: 999;
    top: 5px;
    right: 15px;
    margin: 0;
    background-color: #1422aa;
    border: 2px solid #1422aa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .menu-wrap .navbar-toggle:hover, .menu-wrap .navbar-toggle:focus {
    background-color: #ffffff;
  }
  .menu-wrap .navbar-toggle:hover .icon-bar, .menu-wrap .navbar-toggle:focus .icon-bar {
    background-color: #1422aa;
  }
  .menu-wrap .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }
  .menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li > a:hover,
  .menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li:hover {
    background-color: transparent !important;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1 item-2" "item-5 item-2" "item-8 item-9" "item-8 item-7" "item-4 item-7" "item-10 item-3" "item-11 item-6" "item-11 item-12";
  }
  .s-facts .facts-grid .item {
    position: relative;
  }
  .s-facts .facts-grid .item > div {
    opacity: 1;
  }
  footer .footer-bottom .copy {
    text-align: left;
    margin-top: 10px;
  }
  .razvitum-steps ol li {
    text-align: center;
  }
  .razvitum-steps ol li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 95%;
    height: 1px;
    display: block;
    background-color: #000;
  }
  .razvitum-steps ol li span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    font-size: 12px;
  }
  .razvitum-steps ol li span i.counter {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .request-wrap {
    position: relative;
  }
  .request-wrap .btn-request {
    display: block;
    position: absolute;
    left: 10px;
    top: 20px;
    z-index: 15;
  }
  .request-wrap .btn-request i {
    font-size: 18px;
  }
  .request-wrap .left-aside-bar h4 {
    text-align: left;
    background-color: transparent;
    padding: 10px 5px 10px 65px;
  }
  .request-wrap .left-aside-bar h4:first-child {
    padding: 27px 0 10px 65px;
  }
  .request-wrap .left-aside-bar a.list-group-item {
    color: #666666;
    padding: 10px 0 10px 15px;
    margin-left: 60px;
    text-align: left;
  }
  .request-wrap .left-aside-bar a.list-group-item:hover {
    background-color: #a3d3e5;
    color: #666666;
  }
  .request-wrap .left-aside-bar a.list-group-item span.badge {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 24px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 10px;
    background-color: #1422aa;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
  }
  .request-wrap .left-aside-bar a.list-group-item.active {
    color: #000000;
  }
  .request-wrap .left-aside-bar a.list-group-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #a3d3e5;
  }
  .request-wrap .request-content-head h2, .request-wrap .request-content-head p {
    padding-left: 20px;
  }
  .concept-wrap .column {
    padding-top: 20px;
    padding-bottom: 20px;
    column-width: fill-available;
    column-count: 1;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations {
    width: 45%;
    padding: 15px;
    margin: 5px;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations img {
    width: 80px;
    height: auto;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations p {
    padding-top: 15px;
    font-size: 16px;
  }
  .listeners-wrap .listener-item .programm {
    margin-top: 20px;
  }
  .listeners-wrap .listener-item .status {
    padding-left: 2em;
  }
  .seminar-wrap h1 {
    font-size: 46px;
  }
  .s-loyalty .item-loyalty span {
    font-size: 1.5em;
  }
  .seminar-header h3 {
    font-size: 32px;
  }
  .seminar-header .text {
    text-align: center !important;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .seminar-header .text span {
    font-size: 14px;
  }
  .seminar-header .text p {
    font-size: 20px;
  }
  .study-cases .cases-list {
    column-count: 1;
  }
  .menu-block {
    margin-top: 20px;
  }
  .menu-block .menu-block-link {
    width: 100%;
  }
  .menu-block .menu-block-link a.btn-white {
    display: inline-block;
    margin-right: 0px;
  }
  .menu-block .menu-block-users {
    margin-top: 20px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .menu-block .menu-block-geo {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .menu-block i {
    font-size: 30px;
    margin-right: 10px;
    margin-left: 0px;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img img {
    border-radius: 50%;
    margin: 20px 10px 10px;
    width: 150px;
    height: 150px;
    box-shadow: 0 0 0 20px #f2f2f2;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img p {
    color: #1422aa;
    font-size: 14px;
    padding: 0 15px;
    text-align: center;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-text {
    width: 100%;
    padding: 15px;
  }
  .recommend-colleague .letter-edit-bg {
    position: absolute;
    width: 180px;
    height: auto;
    opacity: 0.2;
    bottom: 5%;
    right: 3%;
  }
  .recommend-colleague .letter-edit-marka {
    text-align: left;
  }
  .semitar-interactive-maps .static-text-map {
    margin-top: 20px;
  }
  #modal-stock .modal-body .item {
    position: relative;
  }
  #modal-stock .modal-body .item::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(142, 198, 255, 0.9);
  }
  #modal-stock .modal-body .modal-custom-form .datepicker .input-date, #modal-stock .modal-body .modal-custom-form .datepicker .input-time {
    margin-bottom: 15px;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 2.5em;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 16.5em;
    text-align: left;
  }
  .vz-header {
    padding: 5px 0;
  }
  .vz-header .logo-wrap .logo img {
    width: 50px;
  }
  .vz-header .header-phone-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 575.98px) {
  .text-columns-5 {
    column-count: 1;
  }
  .text-columns-2 {
    column-count: 1;
  }
  .s-story .bg-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .s-story .bg-wrap .col-6 {
    width: 100%;
    margin: 0 auto;
  }
  .s-story .bg-wrap .item-story {
    width: 100%;
    height: auto;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1" "item-2" "item-2" "item-3" "item-4" "item-5" "item-7" "item-7" "item-9" "item-6" "item-10" "item-8" "item-8" "item-11" "item-12";
  }
  .s-facts .facts-grid .item {
    position: relative;
  }
  .s-facts .facts-grid .item > div {
    opacity: 1;
  }
  .razvitum-steps ol li span {
    padding: 0 10px;
    font-size: 10px;
  }
  .razvitum-steps ol li span i.counter {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations {
    width: 95%;
    padding: 15px;
    margin: 5px;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations img {
    width: 80px;
    height: auto;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations p {
    padding-top: 15px;
    font-size: 16px;
  }
  .seminar-wrap h1 {
    font-size: 36px;
  }
  .seminar-wrap .vz-banner h1 {
    font-size: 1.5em;
  }
  .seminar-wrap .vz-banner h2 {
    font-size: 1.8em !important;
  }
  .s-loyalty .item-loyalty span {
    font-size: 1em;
  }
  .seminar-about-head h1 {
    font-size: 2.1em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  .seminar-about-medal {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
  }
  .seminar-about-medal .item-medal {
    position: relative;
  }
  .seminar-about-medal .item-medal span {
    position: absolute;
    bottom: 26px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    display: block;
  }
  .seminar-about-medal .item-medal span::after {
    content: 'ак.ч.';
    position: absolute;
    left: -3px;
    bottom: -10px;
    font-size: 0.5em;
  }
  .seminar-about-medal .item-medal img {
    width: 100px;
    height: auto;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .s-workshop-participant .box-workshop-participant {
    text-align: center;
    margin: 1.5em 0;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 1.6em;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
}

@media only screen and (max-width: 414.98px) {
  .pull-md-right {
    float: none !important;
  }
  .programs__item-actions .clearfix .pull-right {
    float: left !important;
    margin-top: 15px;
  }
  .seminar-wrap .vz-banner h1 {
    font-size: 1.5em;
  }
  .seminar-wrap .vz-banner h2 {
    font-size: 1.8em !important;
  }
  .vz-wrap .btn-white {
    font-size: 0.8em;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 1.5em;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .nav-justified > li {
    vertical-align: middle;
  }
  #modal-stock .modal-dialog {
    width: 750px;
  }
  .modal-md {
    width: 460px;
  }
  .modal-md .modal-body {
    padding: 30px;
  }
  .position-xl-absolute {
    position: relative;
  }
}

@media only screen and (min-width: 992px) {
  .md-sticky {
    position: sticky;
    top: 0;
  }
  .position-xl-absolute {
    position: absolute;
  }
}

.faq-wrap h2 {
  -webkit-text-stroke-width: .35px;
  color: #545454;
  font-family: RobotoLight, sans-serif;
  font-size: 1.5rem;
}

.faq-wrap textarea.form-control {
  padding: 20px;
}

.faq-wrap input[type='search'] {
  background-image: url('data:image/svg+xml,%3Csvg width="10.239" height="10.239" clip-rule="evenodd" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 2709 2709" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill="%23545454" d="M1026 44c549 0 995 445 995 995 0 223-73 429-197 595l856 856-175 175-852-852c-171 139-389 222-626 222-549 0-995-445-995-995 0-549 445-995 995-995zm0 296c386 0 699 313 699 699s-313 699-699 699-699-313-699-699 313-699 699-699z"/%3E%3C/svg%3E');
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.faq-wrap input[type='search'].waiting {
  background-color: #fff;
  background-image: url("/img/spinner1.gif");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.faq-wrap .faq-category {
  border-radius: 0;
  border-top: 1px solid #cccccc;
  box-shadow: none;
}

.faq-wrap .faq-category .panel-collapse .panel-body {
  border-top: 0;
}

.faq-wrap .faq-category > .panel-collapse > .panel-body {
  padding-right: 0;
}

.faq-wrap .faq-category > .panel-heading {
  border-radius: 0;
  padding: 0;
}

.faq-wrap .faq-category > .panel-heading a {
  border-left: 5px solid #a3d3e5;
  display: block;
  font-weight: bold;
  padding: 15px 20px 15px 15px;
  position: relative;
  transition: background-color ease .5s;
}

.faq-wrap .faq-category > .panel-heading a:hover {
  background-color: transparent;
}

.faq-wrap .faq-category > .panel-heading a .caret {
  position: absolute;
  right: 4px;
  top: 50%;
}

.faq-wrap .faq-category > .panel-heading a.collapsed {
  border-left: 0;
  font-weight: normal;
  padding: 15px 20px;
}

.faq-wrap .faq-category > .panel-heading a.collapsed:hover {
  background-color: #a3d3e5;
}

.faq-wrap .faq-question {
  box-shadow: none;
  margin-bottom: 20px;
  margin-top: 0;
}

.faq-wrap .faq-question > .panel-heading {
  border: 0;
  box-shadow: none;
  padding: 0;
}

.faq-wrap .faq-question > .panel-heading a {
  background-color: #f2f2f2;
  color: #1422aa;
  display: block;
  line-height: 1.5em;
  padding: 10px 25px 10px 15px;
  position: relative;
  transition: background-color ease .5s;
}

.faq-wrap .faq-question > .panel-heading a span {
  position: absolute;
  right: 10px;
  top: 10px;
}

.faq-wrap .faq-question > .panel-heading a:active, .faq-wrap .faq-question > .panel-heading a:focus {
  text-decoration: none;
}

.faq-wrap .faq-question > .panel-heading a.collapsed {
  background-color: transparent;
  color: inherit;
}

.faq-wrap .faq-question > .panel-heading a.collapsed span {
  display: none;
}

.faq-wrap .faq-question > .panel-collapse > .panel-body {
  background-color: transparent;
  transition: background-color ease .5s, background ease .5s;
}

.faq-wrap .faq-question > .panel-collapse > .panel-body p {
  margin-bottom: 15px;
}

.faq-wrap .faq-question > .panel-collapse.collapse.in > .panel-body {
  background-color: #f2f2f2;
}

.faq-wrap .faq-question > .panel-collapse.collapsing > .panel-body {
  background-color: #f2f2f2;
}

.faq-wrap .social-block {
  border-radius: 0;
  padding: 15px;
}

.faq-wrap .social-block h2 {
  font-size: inherit;
  margin-bottom: 15px;
  margin-top: 0;
}

.faq-wrap .social-block a {
  background-image: none;
  border: 2px solid #999;
  border-radius: 50%;
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4em;
  font-weight: 400;
  height: 2em;
  line-height: 2em;
  margin-bottom: 5px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 2em;
}

.faq-wrap .social-block a:hover {
  border-color: #1422aa;
  color: #1422aa;
}

.faq-wrap .contact-types .form-check label {
  font-family: Roboto, Arial, sans-serif;
}

.faq-wrap .contact-types .panel-heading {
  background-color: #fff;
  border-radius: 0;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
}

.faq-wrap .contact-types .panel-heading .caret {
  position: absolute;
  right: 15px;
  top: 50%;
}

.faq-wrap .contact-types .panel-body {
  border-radius: 0;
  border-top: 0 !important;
}

.faq-wrap .contact-types h2 a {
  color: #1422aa;
  display: block;
  font-family: RobotoLight, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  padding: 15px 0;
  text-decoration: none;
}

.faq-wrap .contact-types h2 a:not(.collapsed) .fa-fw {
  color: #1422aa;
}

.faq-wrap .contact-types h2 a:not(.collapsed):focus, .faq-wrap .contact-types h2 a:not(.collapsed):hover {
  text-decoration: none;
}

.faq-wrap .contact-types h2 a.collapsed {
  color: #545454;
}

.faq-wrap .contact-types h2 a.collapsed:focus, .faq-wrap .contact-types h2 a.collapsed:hover {
  color: #000;
}

.faq-wrap .contact-types h2 a.collapsed:focus .fa-fw, .faq-wrap .contact-types h2 a.collapsed:hover .fa-fw {
  color: #000;
}

.faq-wrap .contact-types h2 a .fa-fw {
  color: #999;
  text-align: left;
  width: 2.5rem;
}

.faq-wrap .text-links a {
  font-family: Roboto, sans-serif;
  font-size: 1em;
  color: #545454;
}

.faq-wrap .text-links a:hover {
  color: #1422aa;
}

.faq-wrap .text-links a:hover i {
  color: #1422aa;
}

.faq-wrap .text-links a i {
  font-size: 1.5em;
  color: #cccccc;
  margin-right: 10px;
}

.seminar-about-content {
  position: relative;
}

.seminar-about-content p {
  padding: 10px 20px;
  position: relative;
}

.seminar-about-content .line::before {
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
}

.seminar-about-content .line:first-child::before {
  background-color: #a3d3e5;
}

.seminar-about-content .line:last-child::before {
  background-color: #fecf73;
}

.seminar-about-content .fa-ul {
  position: relative;
}

.seminar-about-content .brace {
  height: 170px;
  opacity: .5;
}

.minor-mark {
  position: relative;
  left: -30px;
  z-index: 10;
  color: #fff;
  padding: 0 1.5em;
  height: 30px;
  text-align: center;
  font-weight: 700;
  line-height: 30px;
}

.minor-mark-blue {
  background-color: #1422aa;
}

.minor-mark-green {
  background-color: #3fa435;
}

.minor-mark-red {
  background-color: red;
}

.minor-mark-orange {
  background-color: #FED073;
}

.minor-mark-lightblue {
  background-color: #AED3E5;
}

.media-list h5 {
  font-size: 15px;
}

.material .custom-media-img {
  align-items: center;
  display: flex;
  margin-top: 2em;
}

.material .custom-media-img .media-body {
  margin-left: 1em;
}

.material .custom-media {
  align-items: center;
  border-bottom: 2px solid #1422aa;
  border-top: 2px solid #1422aa;
  display: flex;
  margin-bottom: 2em;
  margin-top: 2em;
  padding-bottom: 1.5em;
  padding-top: 1.5em;
}

.material .custom-media .box-circle {
  width: 60px;
  height: 60px;
  border-left: 2px solid transparent;
  border-right: 2px solid #1422aa;
  border-bottom: 2px solid #1422aa;
  border-top: 2px solid #1422aa;
  display: block;
  border-radius: 50%;
  transform: rotate(45deg);
  position: relative;
  margin-right: 1.5em;
}

.material .custom-media .box-circle i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 2em;
  color: #1422aa;
}

.material .custom-panel {
  border-radius: 20px !important;
}

.material .custom-panel .panel-footer {
  background-color: #f2f2f2;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 0;
  padding: 20px 15px;
}

.material .custom-panel .panel-footer a {
  align-items: center;
  display: flex;
}

.material .box-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2em 0;
}

.material .box-wrap .box-card {
  cursor: pointer;
  height: 530px;
  margin-bottom: 20px;
  overflow: hidden;
  perspective: 1000px;
  position: relative;
  width: 45%;
}

.material .box-wrap .box-card .front,
.material .box-wrap .box-card .back {
  backface-visibility: hidden;
  bottom: 0;
  height: 100%;
  left: 0;
  padding: 1em;
  position: absolute;
  right: 0;
  top: 0;
  transition: .5s;
  width: 100%;
}

.material .box-wrap .box-card .front img,
.material .box-wrap .box-card .back img {
  width: 180px;
  height: auto;
}

.material .box-wrap .box-card .front {
  background-color: #fff;
  border: 1px solid #ddd;
}

.material .box-wrap .box-card .back {
  background-color: #f6f6f6;
  transform: rotateY(180deg);
}

.material .box-wrap .box-card.active .front {
  transform: rotateY(180deg);
}

.material .box-wrap .box-card.active .back {
  transform: rotateY(360deg);
}

.seminar-about {
  position: relative;
}

.seminar-about-head h1 {
  color: #ffffff;
  font-weight: 700;
  line-height: 1.3em;
  font-size: 3.6em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.seminar-about-head h1 span {
  font-family: RobotoBlack, sans-serif;
  color: #fecf73;
}

.seminar-about-medal {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2.5em;
}

.seminar-about-medal .item-medal {
  position: relative;
}

.seminar-about-medal .item-medal span {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.8em;
  color: #ffffff;
  display: block;
}

.seminar-about-medal .item-medal span::after {
  content: 'ак.ч.';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -13px;
  font-size: 0.5em;
}

.seminar-about-medal .item-medal img {
  width: 140px;
  height: auto;
}

.seminar-about-sertificat {
  height: 100%;
  margin-top: 16.5em;
  text-align: center;
}

.professional-solidarity .media .media-body a .media-heading {
  color: #1422aa;
}

.professional-solidarity .media .media-heading {
  color: #1422aa;
  margin-bottom: .5em;
}

.announcement {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 46px;
  background-color: #f3efe3;
}

.announcement__title {
  margin-bottom: 46px;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: #1422aa;
  font-size: 1.125em;
  line-height: 1.33em;
}

.announcement__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.announcement__date {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  text-align: center;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: #1422aa;
  font-size: 1.125em;
}

.announcement__date:before {
  content: '';
  display: block;
  position: absolute;
  background-image: url(../img/spritePNG.png);
  background-position: -95px 0px;
  width: 43px;
  height: 48px;
  left: calc((115px / 2) - (43px / 2));
  z-index: 1;
  top: 0;
}

.announcement__day {
  padding-top: 9px;
  width: 115px;
  z-index: 12;
  text-align: center;
  font-size: 1.33em;
}

.announcement__month {
  font-family: Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  width: 115px;
}

.announcement__text {
  display: block;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: #333;
  font-size: 1em;
  line-height: 1.5em;
}

.announcement__text:hover {
  color: #333;
}

@media only screen and (max-width: 991.98px) {
  .material .box-wrap .box-card {
    width: 48%;
    height: 450px;
  }
  .material .box-wrap .box-card .front img,
  .material .box-wrap .box-card .back img {
    width: 110px;
    height: auto;
  }
}

@media only screen and (max-width: 575.98px) {
  .material .custom-media-img {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .material .custom-media-img .media-body {
    margin-left: 0em;
    margin-top: 1em;
  }
  .material .box-wrap .box-card {
    width: 80%;
    height: 450px;
  }
  .material .box-wrap .box-card .front img, .material .box-wrap .box-card .back img {
    width: 120px;
    height: auto;
  }
}

@media only screen and (max-width: 414.98px) {
  .material .box-wrap .box-card {
    width: 80%;
    height: 420px;
  }
  .material .box-wrap .box-card .front img,
  .material .box-wrap .box-card .back img {
    width: 80px;
    height: auto;
  }
}

.platforma_parnters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*==========  Desktop First  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1199.98px) {
  header .s-header {
    padding: 40px 0;
  }
  header .s-header .logo img {
    width: 60px;
    height: auto;
    float: left;
    margin-right: 10px;
  }
  header .s-header .logo-title {
    font-size: 15px;
    font-family: Roboto, sans-serif;
    display: inline-block;
  }
  header .s-header .logo-title span {
    font-weight: 400;
    font-size: 10px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.60em;
  }
  .text-columns-5 {
    column-count: 5;
  }
  .text-columns-2 {
    column-count: 2;
  }
  .s-header .logo img {
    width: 70px;
    height: auto;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1 item-2 item-3 item-4" "item-5 item-2 item-6 item-7" "item-8 item-9 item-11 item-7" "item-8 item-10 item-11 item-12";
  }
  .menu-block {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .menu-block .menu-block-link a.btn-white-min {
    font-size: 12px;
  }
  .menu-block .group-menu-block {
    display: flex;
    justify-content: space-between;
  }
  .menu-block .group-menu-block .menu-block-users {
    display: flex;
    align-items: center;
  }
  .menu-block .group-menu-block .menu-block-geo a {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .menu-block .group-menu-block .menu-block-geo a:hover {
    text-decoration: none;
    color: #fecf73;
  }
  .menu-block i {
    font-size: 20px;
  }
  .menu-block span {
    font-size: 16px;
  }
  .menu-block span small {
    font-size: 13px;
  }
  .s-loyalty .item-loyalty span {
    font-size: 1.5em;
  }
  .study-wrap .panel-body a.btn-block {
    font-size: 0.8em;
  }
  .seminar-about-head h1 {
    font-size: 3.4em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  .seminar-about-medal {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 11em;
  }
}

@media only screen and (max-width: 991.98px) {
  .text-columns-5 {
    column-count: 4;
  }
  .text-columns-2 {
    column-count: 2;
  }
  .s-header {
    background-color: #ffffff;
  }
  .s-header .logo img {
    width: 70px;
    height: auto;
  }
  .s-header .header-phone .header-phone-number {
    margin: 0;
  }
  .s-header .header-phone .number {
    font-size: 0;
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid #1422aa;
    position: relative;
    border-radius: 50%;
    transition: all 0.3s;
    text-decoration: none;
  }
  .s-header .header-phone .number::before {
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f095";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #1422aa;
  }
  .s-header .header-phone .number:hover {
    background-color: #1422aa;
  }
  .s-header .header-phone .number:hover::before {
    color: #ffffff;
  }
  .s-header .btn-wrap {
    display: flex;
    justify-content: space-around;
  }
  .s-header .btn-wrap .header-btn {
    position: relative;
  }
  .s-header .btn-wrap .header-btn .btn-login {
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid #1422aa;
    position: relative;
    text-decoration: none;
  }
  .s-header .btn-wrap .header-btn .btn-login::before {
    content: '\f084';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    color: #1422aa;
  }
  .s-header .btn-wrap .header-btn .btn-login:hover::before {
    color: #ffffff;
  }
  .s-header .btn-wrap .header-btn .btn-user {
    padding: 0 !important;
    width: 50px;
    height: 50px;
  }
  .s-header .btn-wrap .header-btn button {
    z-index: 25 !important;
  }
  .s-header .btn-wrap .header-btn .btn-group {
    position: relative;
    display: block;
  }
  .s-header .btn-wrap .header-btn .btn-group span.caret {
    display: none;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu {
    position: absolute;
    left: -220%;
    top: 55px;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li:first-child {
    padding-top: 5px;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li {
    padding: 2px 0;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li i {
    margin-left: 7px;
    color: #1422aa;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu li.divider {
    padding: 0;
    margin: 5px 0;
  }
  .s-header .btn-wrap .header-btn .btn-group .dropdown-menu a {
    padding: 10px 20px;
  }
  .s-header .btn-wrap .header-btn .btn-group.open button {
    background-color: #f9b233 !important;
  }
  .s-header .btn-wrap .header-cart {
    position: relative !important;
  }
  .s-header .btn-wrap .header-cart a {
    font-size: 0;
  }
  .s-header .btn-wrap .header-cart .min-app {
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid #1422aa;
    position: relative;
    border-radius: 50%;
    text-decoration: none;
  }
  .s-header .btn-wrap .header-cart .min-app::before {
    content: '\f15c';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #1422aa;
  }
  .s-header .btn-wrap .header-cart .min-app:hover {
    background-color: #1422aa;
  }
  .s-header .btn-wrap .header-cart .min-app:hover::before {
    color: #ffffff;
  }
  .s-header .btn-wrap .header-cart .min-app span {
    font-size: 12px;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: -5px;
    right: -7px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    background-color: #1422aa;
    color: #ffffff;
    border-radius: 50%;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1 item-2 item-3" "item-5 item-2 item-6" "item-8 item-9 item-11" "item-8 item-7 item-12" "item-4 item-7 item-10";
  }
  .s-facts .facts-grid .item {
    position: relative;
  }
  .s-facts .facts-grid .item > div {
    opacity: 1;
  }
  .request-wrap .left-aside-bar h4 {
    position: relative;
    padding: 10px;
    text-align: center;
    text-align-background-color: transparent;
  }
  .request-wrap .left-aside-bar h4:first-child {
    padding: 10px 0 10px 0px;
    margin: 0;
  }
  .request-wrap .left-aside-bar h4 i {
    font-size: 30px;
    text-align: center;
    display: inline-block;
  }
  .request-wrap .left-aside-bar a.list-group-item {
    border: none;
    background-color: transparent;
    position: relative;
    transition: all 0.2s linear;
    color: #666666;
    padding: 20px 0 15px 0;
    text-align: center;
  }
  .request-wrap .left-aside-bar a.list-group-item:hover {
    background-color: transparent;
    color: #666666;
  }
  .request-wrap .left-aside-bar a.list-group-item span.badge {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    line-height: 18px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 5px;
    background-color: #1422aa;
    top: 15px;
    font-size: 10px;
  }
  .request-wrap .left-aside-bar a.list-group-item i {
    font-size: 22px;
  }
  .request-wrap .left-aside-bar a.list-group-item.active {
    color: #000000;
    background-color: transparent;
    position: relative;
  }
  .request-wrap .left-aside-bar a.list-group-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #a3d3e5;
  }
  .request-wrap .left-aside-bar a.list-group-item.active:hover {
    color: #666666;
  }
  footer .footer-bottom .footer-links {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  footer .footer-bottom .footer-links ul li {
    display: block;
    margin-right: 0px;
  }
  footer .footer-bottom .copy {
    text-align: left;
  }
  .razvitum-steps ol li {
    text-align: center;
  }
  .razvitum-steps ol li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 95%;
    height: 1px;
    display: block;
    background-color: #000;
  }
  .razvitum-steps ol li span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-right: 0px;
  }
  .razvitum-steps ol li span i.counter {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .seminar-header h3 {
    font-size: 32px;
  }
  .seminar-header .text span {
    font-size: 14px;
  }
  .seminar-header .text p {
    font-size: 20px;
  }
  .seminar-block-text .btn-white {
    font-size: 0.8em;
  }
  .seminar-block-text p {
    font-size: 1.2em;
  }
  .seminar-block-text p span {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 700;
  }
  .study-wrap .panel-body a.btn-block {
    font-size: 1em;
  }
  .seminar-about-head h1 {
    font-size: 3.4em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  .seminar-about-medal {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: left;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 3.7em;
    position: relative;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    position: relative;
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .mt-sm-4 {
    margin-top: 4em !important;
  }
  .mt-sm-3 {
    margin-top: 3em !important;
  }
  header .s-header {
    padding-top: 18px !important;
    background: #fff;
  }
  header .s-header .navbar-toggle .icon-bar {
    width: 100% !important;
  }
  header .s-header .logo-wrap a.logo {
    padding: 0;
  }
  header .s-header .logo-wrap .logo {
    margin: 0 !important;
    display: block;
    padding-top: 10px;
    position: fixed;
    top: 5px;
    left: 10px;
    z-index: 20;
  }
  header .s-header .logo-wrap .logo img {
    width: 50px !important;
    height: auto;
    float: none;
    margin-right: 0px;
  }
  header .s-header .logo-wrap .logo-title {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    display: block;
    padding-top: 5px;
    display: none;
  }
  header .s-header .logo-wrap .logo-title span {
    font-weight: 400;
    font-size: 10px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.55em;
    text-align: center;
  }
  header .s-header .header-phone {
    position: fixed;
    top: 5px;
    right: 135px;
    z-index: 20;
  }
  header .s-header .btn-wrap .header-cart {
    position: fixed !important;
    display: block;
    top: 5px !important;
    right: 195px !important;
    z-index: 20;
    left: unset !important;
    transform: translateX(0) !important;
    height: 50px;
  }
  header .s-header .btn-wrap .header-btn {
    position: fixed;
    top: 5px;
    right: 75px;
    z-index: 20;
  }
  .text-columns-5 {
    column-count: 2;
  }
  .text-columns-2 {
    column-count: 1;
  }
  .s-story {
    padding-bottom: 50px;
  }
  .s-story .text {
    padding-bottom: 30px;
    font-size: 18px;
  }
  .s-story .bg-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .s-story .bg-wrap .col-6 {
    width: 50%;
  }
  .s-story .bg-wrap .item-story {
    width: 100%;
  }
  .dropdown-menu {
    position: relative;
  }
  .navbar-collapse {
    overflow-y: scroll;
    height: 100vh;
  }
  .menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 19;
    background: #fff !important;
  }
  .menu-wrap .navbar.navbar-default {
    background-color: #ffffff;
    border-bottom: 1px solid #1422aa;
  }
  .menu-wrap .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff;
  }
  .menu-wrap .dropdown-menu > li > a:focus, .menu-wrap .dropdown-menu > li > a:hover {
    background-color: transparent !important;
    background-image: none !important;
  }
  .menu-wrap .buttom-menu {
    background-color: #1422aa;
  }
  .menu-wrap .buttom-menu .container {
    padding: 0;
  }
  .menu-wrap .buttom-menu ul {
    text-align: left !important;
  }
  .menu-wrap .buttom-menu ul li {
    width: 100%;
  }
  .menu-wrap .buttom-menu ul li a {
    color: #ffffff;
    text-transform: uppercase;
    text-align: left;
  }
  .menu-wrap .navbar {
    min-height: 60px;
  }
  .menu-wrap .navbar-toggle {
    position: fixed;
    z-index: 999;
    top: 5px;
    right: 15px;
    margin: 0;
    background-color: #1422aa;
    border: 2px solid #1422aa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .menu-wrap .navbar-toggle:hover, .menu-wrap .navbar-toggle:focus {
    background-color: #ffffff;
  }
  .menu-wrap .navbar-toggle:hover .icon-bar, .menu-wrap .navbar-toggle:focus .icon-bar {
    background-color: #1422aa;
  }
  .menu-wrap .navbar-toggle .icon-bar {
    background-color: #ffffff;
  }
  .menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li > a:hover,
  .menu-wrap .buttom-menu .nav > li > a:focus, .menu-wrap .buttom-menu .nav > li:hover {
    background-color: transparent !important;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1 item-2" "item-5 item-2" "item-8 item-9" "item-8 item-7" "item-4 item-7" "item-10 item-3" "item-11 item-6" "item-11 item-12";
  }
  .s-facts .facts-grid .item {
    position: relative;
  }
  .s-facts .facts-grid .item > div {
    opacity: 1;
  }
  footer .footer-bottom .copy {
    text-align: left;
    margin-top: 10px;
  }
  .razvitum-steps ol li {
    text-align: center;
  }
  .razvitum-steps ol li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 95%;
    height: 1px;
    display: block;
    background-color: #000;
  }
  .razvitum-steps ol li span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    font-size: 12px;
  }
  .razvitum-steps ol li span i.counter {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .request-wrap {
    position: relative;
  }
  .request-wrap .btn-request {
    display: block;
    position: absolute;
    left: 10px;
    top: 20px;
    z-index: 15;
  }
  .request-wrap .btn-request i {
    font-size: 18px;
  }
  .request-wrap .left-aside-bar h4 {
    text-align: left;
    background-color: transparent;
    padding: 10px 5px 10px 65px;
  }
  .request-wrap .left-aside-bar h4:first-child {
    padding: 27px 0 10px 65px;
  }
  .request-wrap .left-aside-bar a.list-group-item {
    color: #666666;
    padding: 10px 0 10px 15px;
    margin-left: 60px;
    text-align: left;
  }
  .request-wrap .left-aside-bar a.list-group-item:hover {
    background-color: #a3d3e5;
    color: #666666;
  }
  .request-wrap .left-aside-bar a.list-group-item span.badge {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 24px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 10px;
    background-color: #1422aa;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
  }
  .request-wrap .left-aside-bar a.list-group-item.active {
    color: #000000;
  }
  .request-wrap .left-aside-bar a.list-group-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #a3d3e5;
  }
  .request-wrap .request-content-head h2, .request-wrap .request-content-head p {
    padding-left: 20px;
  }
  .concept-wrap .column {
    padding-top: 20px;
    padding-bottom: 20px;
    column-width: fill-available;
    column-count: 1;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations {
    width: 45%;
    padding: 15px;
    margin: 5px;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations img {
    width: 80px;
    height: auto;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations p {
    padding-top: 15px;
    font-size: 16px;
  }
  .listeners-wrap .listener-item .programm {
    margin-top: 20px;
  }
  .listeners-wrap .listener-item .status {
    padding-left: 2em;
  }
  .seminar-wrap h1 {
    font-size: 46px;
  }
  .s-loyalty .item-loyalty span {
    font-size: 1.5em;
  }
  .seminar-header h3 {
    font-size: 32px;
  }
  .seminar-header .text {
    text-align: center !important;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .seminar-header .text span {
    font-size: 14px;
  }
  .seminar-header .text p {
    font-size: 20px;
  }
  .study-cases .cases-list {
    column-count: 1;
  }
  .menu-block {
    margin-top: 20px;
  }
  .menu-block .menu-block-link {
    width: 100%;
  }
  .menu-block .menu-block-link a.btn-white {
    display: inline-block;
    margin-right: 0px;
  }
  .menu-block .menu-block-users {
    margin-top: 20px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .menu-block .menu-block-geo {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .menu-block i {
    font-size: 30px;
    margin-right: 10px;
    margin-left: 0px;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img img {
    border-radius: 50%;
    margin: 20px 10px 10px;
    width: 150px;
    height: 150px;
    box-shadow: 0 0 0 20px #f2f2f2;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-img p {
    color: #1422aa;
    font-size: 14px;
    padding: 0 15px;
    text-align: center;
  }
  .seminar-wrap .seminar-carousel .carousel-inner .item-wrap-text {
    width: 100%;
    padding: 15px;
  }
  .recommend-colleague .letter-edit-bg {
    position: absolute;
    width: 180px;
    height: auto;
    opacity: 0.2;
    bottom: 5%;
    right: 3%;
  }
  .recommend-colleague .letter-edit-marka {
    text-align: left;
  }
  .semitar-interactive-maps .static-text-map {
    margin-top: 20px;
  }
  #modal-stock .modal-body .item {
    position: relative;
  }
  #modal-stock .modal-body .item::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(142, 198, 255, 0.9);
  }
  #modal-stock .modal-body .modal-custom-form .datepicker .input-date, #modal-stock .modal-body .modal-custom-form .datepicker .input-time {
    margin-bottom: 15px;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 2.5em;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 16.5em;
    text-align: left;
  }
  .vz-header {
    padding: 5px 0;
  }
  .vz-header .logo-wrap .logo img {
    width: 50px;
  }
  .vz-header .header-phone-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 575.98px) {
  .text-columns-5 {
    column-count: 1;
  }
  .text-columns-2 {
    column-count: 1;
  }
  .s-story .bg-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .s-story .bg-wrap .col-6 {
    width: 100%;
    margin: 0 auto;
  }
  .s-story .bg-wrap .item-story {
    width: 100%;
    height: auto;
  }
  .s-facts .facts-grid {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-areas: "item-1" "item-2" "item-2" "item-3" "item-4" "item-5" "item-7" "item-7" "item-9" "item-6" "item-10" "item-8" "item-8" "item-11" "item-12";
  }
  .s-facts .facts-grid .item {
    position: relative;
  }
  .s-facts .facts-grid .item > div {
    opacity: 1;
  }
  .razvitum-steps ol li span {
    padding: 0 10px;
    font-size: 10px;
  }
  .razvitum-steps ol li span i.counter {
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations {
    width: 95%;
    padding: 15px;
    margin: 5px;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations img {
    width: 80px;
    height: auto;
  }
  .summary-wrap .item-summary .situations-wrap .item-situations p {
    padding-top: 15px;
    font-size: 16px;
  }
  .seminar-wrap h1 {
    font-size: 36px;
  }
  .seminar-wrap .vz-banner h1 {
    font-size: 1.5em;
  }
  .seminar-wrap .vz-banner h2 {
    font-size: 1.8em !important;
  }
  .s-loyalty .item-loyalty span {
    font-size: 1em;
  }
  .seminar-about-head h1 {
    font-size: 2.1em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  .seminar-about-medal {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
  }
  .seminar-about-medal .item-medal {
    position: relative;
  }
  .seminar-about-medal .item-medal span {
    position: absolute;
    bottom: 26px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    display: block;
  }
  .seminar-about-medal .item-medal span::after {
    content: 'ак.ч.';
    position: absolute;
    left: -3px;
    bottom: -10px;
    font-size: 0.5em;
  }
  .seminar-about-medal .item-medal img {
    width: 100px;
    height: auto;
  }
  .seminar-about-sertificat {
    height: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .s-workshop-participant .box-workshop-participant {
    text-align: center;
    margin: 1.5em 0;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 1.6em;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
}

@media only screen and (max-width: 414.98px) {
  .pull-md-right {
    float: none !important;
  }
  .programs__item-actions .clearfix .pull-right {
    float: left !important;
    margin-top: 15px;
  }
  .seminar-wrap .vz-banner h1 {
    font-size: 1.5em;
  }
  .seminar-wrap .vz-banner h2 {
    font-size: 1.8em !important;
  }
  .vz-wrap .btn-white {
    font-size: 0.8em;
  }
  .lms-menu-wrap {
    position: relative;
  }
  .lms-menu-wrap .lms-menu {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    transition: width 0.1s linear;
  }
  .lms-menu-wrap .lms-menu #btn-menu-bars {
    cursor: pointer;
  }
  .lms-menu-wrap .lms-menu i {
    font-style: normal;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul {
    display: flex;
  }
  .lms-menu-wrap .lms-menu ul.fa-ul li {
    margin-right: 1.5em;
  }
  .lms-menu-wrap .lms-menu .collapse-wrap {
    background-color: #ACACAC;
    position: relative;
    margin-top: 2.5em !important;
  }
  .lms-menu-wrap .lms-menu .final-test {
    border: none !important;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .nav-justified > li {
    vertical-align: middle;
  }
  #modal-stock .modal-dialog {
    width: 750px;
  }
  .modal-md {
    width: 460px;
  }
  .modal-md .modal-body {
    padding: 30px;
  }
  .position-xl-absolute {
    position: relative;
  }
}

@media only screen and (min-width: 992px) {
  .md-sticky {
    position: sticky;
    top: 0;
  }
  .position-xl-absolute {
    position: absolute;
  }
}
