@import 'main';
@import 'help';
@import 'mixins/calc';
@import 'spritePNG';

.seminar-about-content {
  position: relative;

  p {
    padding: 10px 20px;
    position: relative; }

  .line {
    &::before {
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 10px; }

    &:first-child {
      &::before {
        background-color: $active-color; } }

    &:last-child {
      &::before {
        background-color: $orange; } } }

  .fa-ul {
    position: relative; }

  .brace {
    height: 170px;
    opacity: .5; } }

.minor-mark {
  position: relative;
  left: -30px;
  z-index: 10;
  color: $color-white;
  padding: 0 1.5em;
  height: 30px;
  text-align: center;
  font-weight: 700;
  line-height: 30px; }

.minor-mark-blue {
  background-color: $accent; }

.minor-mark-green {
  background-color: $green; }

.minor-mark-red {
  background-color: red; }

.minor-mark-orange {
  background-color: #FED073; }

.minor-mark-lightblue {
  background-color: #AED3E5; }

.media-list {
  h5 {
    font-size: 15px; } }

.material {
  .custom-media-img {
    align-items: center;
    display: flex;
    margin-top: 2em;

    .media-body {
      margin-left: 1em; } }

  .custom-media {
    align-items: center;
    border-bottom: 2px solid #1422aa;
    border-top: 2px solid #1422aa;
    display: flex;
    margin-bottom: 2em;
    margin-top: 2em;
    padding-bottom: 1.5em;
    padding-top: 1.5em;

    .box-circle {
      width: 60px;
      height: 60px;
      border-left: 2px solid transparent;
      border-right: 2px solid #1422aa;
      border-bottom: 2px solid #1422aa;
      border-top: 2px solid #1422aa;
      display: block;
      border-radius: 50%;
      transform: rotate(45deg);
      position: relative;
      margin-right: 1.5em;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        font-size: 2em;
        color: #1422aa; } } }

  .custom-panel {
    border-radius: 20px !important;

    .panel-footer {
      background-color: #f2f2f2;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top: 0;
      padding: 20px 15px;

      a {
        align-items: center;
        display: flex; } } }

  .box-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2em 0;

    .box-card {
      cursor: pointer;
      height: 530px;
      margin-bottom: 20px;
      overflow: hidden;
      perspective: 1000px;
      position: relative;
      width: 45%;

      .front,
      .back {
        backface-visibility: hidden;
        bottom: 0;
        height: 100%;
        left: 0;
        padding: 1em;
        position: absolute;
        right: 0;
        top: 0;
        transition: .5s;
        width: 100%;

        img {
          width: 180px;
          height: auto; } }

      .front {
        background-color: #fff;
        border: 1px solid #ddd; }

      .back {
        background-color: #f6f6f6;
        transform: rotateY(180deg); }

      &.active {
        .front {
          transform: rotateY(180deg); }

        .back {
          transform: rotateY(360deg); } } } } }

.seminar-about {
  position: relative;

  &-head {
    h1 {
      color: #ffffff;
      font-weight: 700;
      line-height: 1.3em;
      font-size: 3.6em;
      margin-top: 1em;
      margin-bottom: 1em;

      span {
        font-family: $black-font;
        color: $orange; } } }

  &-medal {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2.5em;

    .item-medal {
      position: relative;

      span {
        position: absolute;
        bottom: 32px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 1.8em;
        color: #ffffff;
        display: block;

        &::after {
          content: 'ак.ч.';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -13px;
          font-size: 0.5em; } }

      img {
        width: 140px;
        height: auto; } } }

  &-sertificat {
    height: 100%;
    margin-top: 16.5em;
    text-align: center; } }

.professional-solidarity {
  .media {
    .media-body {
      a {
        .media-heading {
          color: $accent; } } }

    .media-heading {
      color: $accent;
      margin-bottom: .5em; } } }

// Анонс
.announcement {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 46px;
  background-color: #f3efe3;
  $day-width: 115px;

  &__title {
    margin-bottom: 46px;
    @include ff-arial-bold;
    color: $accent;
    font-size: 1.125em;
    line-height: 1.33em; }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px; }

  &__date {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    text-align: center;
    @include ff-arial-bold;
    color: $accent;
    font-size: 1.125em;

    &:before {
      @include psblock(block, absolute);
      @include sprite($calendar);
      @include leftcalc($calendar-width, $day-width);
      z-index: 1;
      top: 0; } }

  &__day {
    padding-top: 9px;
    width: $day-width;
    z-index: 12;
    text-align: center;
    font-size: 1.33em; }

  &__month {
    @include ff-arial;
    text-align: center;
    width: $day-width; }

  &__text {
    display: block;
    @include ff-arial-bold;
    color: $headgray;
    font-size: 1em;
    line-height: 1.5em;

    &:hover {
      color: $headgray; } } }


// Medium Devices, Desktops
@media only screen and (max-width: 991.98px) {
  .material {
    .box-wrap {
      .box-card {
        width: 48%;
        height: 450px;

        .front,
        .back {
          img {
            width: 110px;
            height: auto; } } } } } }

// Extra Small Devices, Phones
@media only screen and (max-width: 575.98px) {
  .material {
    .custom-media-img {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .media-body {
        margin-left: 0em;
        margin-top: 1em; } }

    .box-wrap {
      .box-card {
        width: 80%;
        height: 450px;

        .front, .back {
          img {
            width: 120px;
            height: auto; } } } } } }

// Custom, iPhone Retina
@media only screen and (max-width: 414.98px) {
  .material {
    .box-wrap {
      .box-card {
        width: 80%;
        height: 420px;

        .front,
        .back {
          img {
            width: 80px;
            height: auto; } } } } } }

//баннеры партнеров
.platforma_parnters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

// Всегда в конце
@import 'media';
